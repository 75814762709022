import _ from 'lodash';

import { IStateReducer, IAction } from './types';
import * as actionTypes from "./PatientFormActions";

const prefix = 'PATIENT_FORMS_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_FORMS: `${prefix}SET_FORMS`,
    SET_INCLUDE_SOAP_NOTE_FORMS: `${prefix}SET_INCLUDE_SOAP_NOTE_FORMS`,
};

const initialFormsData = {
    summary: {},
    IncludeSoapNoteForms: {}
}

const initialPatientData = {
    forms: { ...initialFormsData }
}

const initialState: IStateReducer = {};

const PatientFormReducer = (
    state: IStateReducer = { ...initialState },
    action: IAction
) => {
    const checkExist = () => {
        let newState = _.cloneDeep(state)
        if (!newState[action.subjectId]) {
            newState = {
                ...newState, [action.subjectId]: _.cloneDeep(initialPatientData)
            }
        }
        return newState
    }
    switch (action.type) {
        case actionsTypes.SET_INCLUDE_SOAP_NOTE_FORMS: {
            let newState = checkExist()
            newState[action.subjectId].forms.IncludeSoapNoteForms = { ...newState[action.subjectId].forms.IncludeSoapNoteForms, ...action.payload };
            return newState;
        }
        case actionsTypes.SET_FORMS: {
            let newState = checkExist()
            newState[action.subjectId].forms.summary = { ...newState[action.subjectId].forms.summary, ...action.payload };
            return newState;
        }
        case actionsTypes.SET: {
            let newState = checkExist()
            newState[action.subjectId].forms.summary = { ...action.payload };
            return newState;
        }
        case actionTypes.FORMS_PATIENTS: {
            let newState = checkExist()
            newState[action.subjectId].formsPatients = action.payload;
            return newState;
        }
        case actionTypes.OPEN_SORT_FORM: {
            let newState = checkExist()
            newState[action.subjectId].openSortForm = action.payload;
            return newState;
        }
        case actionTypes.OPEN_FILTER_FORM: {
            let newState = checkExist()
            newState[action.subjectId].openFilterForm = action.payload;
            return newState;
        }
        case actionTypes.FORM_LIST_SELECTED_ITEM: {
            let newState = checkExist()
            newState[action.subjectId].formListSelectedItem = action.payload;
            return newState;
        }
        case actionTypes.SET_SORT: {
            let newState = checkExist()
            newState[action.subjectId].sort = action.payload;
            return newState;
        }
        case actionTypes.SET_FILTERS: {
            let newState = checkExist()
            newState[action.subjectId].filter = action.payload;
            return newState;
        }
        case actionTypes.SET_SCROLL: {
            let newState = checkExist()
            newState[action.subjectId].scroll = action.payload;
            return newState;
        }
        case actionTypes.SET_CURRENT_NOTE: {
            let newState = checkExist()
            newState[action.subjectId].currentNote = action.payload;
            return newState;
        }
        default:
            return state;
    }
};
export default PatientFormReducer;