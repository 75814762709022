import React, { FC, useRef } from 'react';
import cn from 'classnames';
import { Select } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import { v4 } from 'uuid';

import { ICommon } from 'src/Framework/Controls/SelectContainer/index';

import Option from './Option';

const ArrowDown =
  require('src/Framework/Common/Svg/messaging/arrow-down.svg').default;

export interface ISelect extends ICommon {
  type: 'select';
  checkboxOptions?: boolean;
  rowSelectOptions?: boolean;
  homeRef?: React.MutableRefObject<any>;
  value?: any;
  options?: any[];
  onChange?: any;
  onBlur?: any;
  className?: string;
  hasError?: boolean;
  inputProps?: any;
  getPopupContainer?: any;
  asyncOnChange?: (value: any, option: any) => Promise<any>;
  helpTooltipProps?: Partial<TooltipProps>;
  allOptions?: any[];
  disableNullValidation?: boolean;
  isOpened?: boolean;
  avoidOptionsSorting?: boolean;
  disableDefaultSort?: boolean;
  disableFilterOption?: boolean;
  disableEllipsis?: boolean;
  enableTextColorOption?: boolean;
  optionIcons?: boolean;
}

interface IOwnProps {
  setAsyncLoading: Function;
}

type IProps = ISelect & IOwnProps;

const Component: FC<IProps> = ({
  checkboxOptions,
  rowSelectOptions,
  homeRef,
  label,
  bottomMargin,
  value,
  options,
  onChange,
  onBlur,
  className,
  hasError,
  inputProps,
  getPopupContainer,
  asyncOnChange,
  setAsyncLoading,
  allOptions,
  hideLabel,
  id,
  star,
  disableNullValidation,
  isOpened,
  disableDefaultSort,
  avoidOptionsSorting,
  disableFilterOption,
  disableEllipsis,
  connectToContainerOnScroll,
  containerRef,
  enableTextColorOption,
  optionIcons
}: IProps) => {
  const labelIdRef = useRef(v4());
  const optionsList = options
    ? [...options].map((v) => {
        if (!v.label) {
          return {
            ...v,
            label: v.title || ''
          };
        }
        return v;
      })
    : [];
  const optionExist = value
    ? options?.find((v: any) => v.value == value)
    : true;
  if (!optionExist && allOptions) {
    const deletedOption = allOptions?.find((v) => v.value == value);
    if (deletedOption) {
      optionsList.unshift({
        ...deletedOption,
        disabled: true,
        tooltip: 'Item was removed or inactive'
      });
    }
  }
  const getSelectedOptionLabel = () => {
    const item = optionsList.find((item) => item.value === value);
    return item?.optionSearch || item?.label || '';
  };

  const sortedList =
    avoidOptionsSorting || disableDefaultSort
      ? [...optionsList]
      : [...optionsList].sort((a, b) =>
          a.optionSearch && b.optionSearch
            ? a.optionSearch?.localeCompare(b.optionSearch)
            : typeof a.label === 'string' && typeof b.label === 'string'
            ? a.label?.localeCompare(b.label)
            : 0
        );
  return (
    <>
      <label
        id={labelIdRef.current}
        htmlFor={inputProps.id ? inputProps.id : id}
        className="visually-hidden"
      >
        {label}
        {value ? ': ' + getSelectedOptionLabel() : ''}
      </label>
      <Select
        aria-required={star}
        aria-labelledby={labelIdRef.current}
        dropdownRender={(originNode: any) => (
          <div id={`${inputProps?.id || id}-select-dropdown-container`}>
            {originNode}
          </div>
        )}
        showSearch={true}
        optionFilterProp="label"
        filterOption={(input: any, option: any) => {
          if (disableFilterOption) return true;
          const item = optionsList.find((v) => v.value == option.value);
          if (item) {
            if (item.hasOwnProperty('optionSearch') && item.optionSearch) {
              return `${item.optionSearch}`
                .toLowerCase()
                .includes(input.toLowerCase());
            }
            return `${item.label}`.toLowerCase().includes(input.toLowerCase());
          }
          return false;
        }}
        id={id}
        {...inputProps}
        value={
          disableNullValidation ? value : value === null ? undefined : value
        }
        onChange={async (value: string, option: any) => {
          onChange && onChange(value, option);
          if (asyncOnChange) {
            setAsyncLoading(true);
            await asyncOnChange(value, option);
            setAsyncLoading(false);
          }
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(value);
          }
        }}
        className={cn({
          select: true,
          error: hasError,
          margin: bottomMargin,
          [`${className}`]: className
        })}
        placeholder={
          inputProps?.hasOwnProperty('placeholder')
            ? inputProps?.placeholder
            : hideLabel
            ? label
            : ''
        }
        dropdownStyle={{
          zIndex: 10000
        }}
        getPopupContainer={
          getPopupContainer
            ? getPopupContainer
            : connectToContainerOnScroll && containerRef
            ? () => containerRef.current
            : undefined
        }
        suffixIcon={
          <>
            {inputProps?.suffixIcon ? inputProps?.suffixIcon : null}
            <div
              className="arrow-down"
              style={{
                WebkitMaskImage: `url(${ArrowDown})`,
                maskImage: `url(${ArrowDown})`
              }}
            />
          </>
        }
        virtual={false}
      >
        {sortedList?.map((val, index) =>
          Option({
            val,
            value,
            checkboxOptions,
            rowSelectOptions,
            homeRef,
            isLast: optionsList.length - 1 === index,
            isOpened,
            disableEllipsis,
            enableTextColorOption,
            optionIcons
          })
        )}
      </Select>
    </>
  );
};

export default Component;
