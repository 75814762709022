import React, { FC, useMemo } from 'react';
import { Resizable } from 're-resizable';

import { IModalSliderProps } from './index';
import Header from './Header';

import { ColResizer, A11yWrapper } from './styled';

interface IProps extends IModalSliderProps {}

const handleComponent = {
  left: (
    <ColResizer>
      <div />
    </ColResizer>
  )
};

const enable = {
  left: true
};

const Component: FC<IProps> = (props) => {
  const { defaultWidth, children, onClose, minWidth, id, hideHeader } = props;

  const defaultSize = useMemo(() => {
    return {
      width: defaultWidth || '500px',
      height: '100%'
    };
  }, [defaultWidth]);
  return (
    <Resizable
      minWidth={minWidth || '250px'}
      maxWidth={'75vw'}
      defaultSize={defaultSize}
      handleComponent={handleComponent}
      enable={enable}
      className="slider-resizable-container"
    >
      <A11yWrapper id={id} role="dialog" aria-modal="true">
        {!hideHeader && <Header {...props} onClose={onClose} />}
        {children}
      </A11yWrapper>
    </Resizable>
  );
};

export default React.memo(Component);
