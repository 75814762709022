import moment from 'moment';
import _ from 'lodash';

import store from 'src/store';

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from './ApiUrl';
import { ApiUrl as commonApiUrl } from 'src/Framework/Common/ApiUrl';

import { actionsTypes } from '../../ScheduleReducers/ScheduleDetailsReducer/index';
import {
  ICalendarSettings,
  IFilter,
  IScheduleClinic
} from '../../ScheduleReducers/ScheduleDetailsReducer/types';
import { IAppointment } from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/types';
import { IScheduleEvent } from '../../ScheduleEventsReducer/types';
import { ApiUrl as GlobalApiUrl } from 'src/Framework/Common/ApiUrl';
import { IPatientInsurance } from 'src/Activities/Subject/Patients/Insurance/store/types';
import { changeCalendarSettings } from '..';

export const UPDATE_CLINICS = 'UPDATE_CLINICS';
export const SET_SCHEDULER_ACTIVITY_SUBTITLE =
  'SET_SCHEDULER_ACTIVITY_SUBTITLE';

export const UPDATE_WAITING_GROUPS = 'UPDATE_WAITING_GROUPS';
export const UPDATE_WAITING_GROUP_PATIENTS = 'UPDATE_WAITING_GROUP_PATIENTS';
export const UPDATE_APPOINTMENT_HISTORY = 'UPDATE_APPOINTMENT_HISTORY';
export const UPDATE_APPOINTMENT_LOG = 'UPDATE_APPOINTMENT_LOG';
export const UPDATE_SUBJECT_SUMMARY = 'UPDATE_SUBJECT_SUMMARY';
export const UPDATE_SUBJECT_SEARCH = 'UPDATE_SUBJECT_SEARCH';
export const UPDATE_SUBJECT_PREFERENCE_FIELDS = 'GET_SUBJECT_PREFERENCE_FIELDS';
export const EDIT_WAITING_LIST_ITEM = 'EDIT_WAITING_LIST_ITEM';
export const ADD_EVENT = 'ADD_EVENT';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const REMOVE_DRAGGING_ITEM = 'REMOVE_DRAGGING_ITEM';
export const REORDER_COLUMNS = 'REORDER_COLUMNS';

export const updateDragProgress = (payload: boolean) => {
  const root_theme = document.querySelector(':root');
  if (root_theme) {
    // @ts-ignore
    root_theme.style.setProperty(
      '--drag-drop-calendar',
      payload ? '10' : 'unset'
    );
  }
};

export const updateFilters = async (
  payload: Partial<IFilter>,
  savePref = true
) => {
  const filters = store.getState().scheduleDetails.filters;
  const data = { ...filters, ...payload };
  const savedFilters: ICalendarSettings = {
    reservationsOnly: data.reservationsOnly,
    isShowingPatientName: data.isShowingPatientName,
    isShowingReservationLabel: data.isShowingReservationLabel,
    weekFilter: data.weekFilter,
    zoom: data.zoom,
    showCancellations: data.showCancellations,
    selectAllProviders: data.selectAllProviders,
    pageLength: data.pageLength,
    favoriteId: data.favoriteId,
    initialLoadId: data.initialLoadId,
    reservationLabelOpacity: data.reservationLabelOpacity
  };
  store.dispatch({
    type: actionsTypes.SET_FILTERS,
    payload
  });
  if (savePref) {
    await changeCalendarSettings(savedFilters);
  }
};

export const setFilteredSlices = (payload: any[]) => {
  store.dispatch({
    type: actionsTypes.SET_FILTERED_SLICES,
    payload
  });
};

export const updateClinics = (clinics: IScheduleClinic[]) => {
  return {
    type: UPDATE_CLINICS,
    payload: clinics
  };
};

export const getClinics = () => {
  return APIHandler.AxiosInstance.post(ApiUrl.GET_CLINICS, null, {
    withCredentials: true
  });
};

export const getProvidersFromClinics = (
  clinicCodes: string[],
  type: number = 1
) => {
  return APIHandler.AxiosInstance.post(
    commonApiUrl.GET_PROVIDERS_FROM_CLINICS,
    {
      typeToRetrieve: type,
      clinicCodes, //: ["Counsel", "SouthCNC", "North", "West"],
      includeInactive: false
    },
    { withCredentials: true }
  );
};

export const getScheduleSlices = (
  orderColumns: boolean,
  viewType: any,
  startDate: string,
  endDate: string,
  clinicCodes: string[],
  providerCodes: string[]
) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_SCHEDULE_SLICES,
    {
      orderColumns,
      viewType,
      startDate,
      endDate,
      clinicCodes,
      providerCodes
    },
    { withCredentials: true }
  );
};

export const getScheduleSliceEvents = (scheduleSlices: any[]) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_SCHEDULE_SLICE_EVENTS,
    {
      scheduleSlices
    },
    { withCredentials: true }
  );
};

export const updateClinicWaitingList = (
  reqTime: number,
  waitingGroups: any[]
) => {
  return {
    type: UPDATE_WAITING_GROUPS,
    payload: {
      reqTime,
      waitingGroups
    }
  };
};

export const getWaitingLists = (clinicCodes: string[]) => {
  return APIHandler.AxiosInstance.get(ApiUrl.GET_WAITING_LISTS, {
    withCredentials: true,
    params: {
      clinicCodes
    }
  });
};

export const getClinicWaitingList = (clinicCodes: string[]) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_WAITING_GROUPS_FROM_CLINICS,
    {
      showInAppointment: 1,
      clinicCodes
    },
    { withCredentials: true }
  );
};

export const updateWaitingPatients = (
  reqTime: number,
  waitingGroupPatients: any[]
) => {
  return {
    type: UPDATE_WAITING_GROUP_PATIENTS,
    payload: {
      reqTime,
      waitingGroupPatients
    }
  };
};

export const getWaitingPatientsFromListCodes = (
  clinicCodes: string[],
  waitingListCodes: string[]
) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_WAITING_PATIENTS_FROM_LISTCODES,
    {
      startIndex: '0',
      count: '500',
      clinicCodes,
      waitingListCodes
    },
    { withCredentials: true }
  );
};

const prepareSeriesData = (newEvent: any, isUpdating: boolean) => {
  var eventPayload = _.cloneDeep(newEvent);
  eventPayload.scheduleEvent.series.startDate = isUpdating
    ? eventPayload.scheduleEvent.scheduleEventDateTime.slice(0, 10)
    : eventPayload.scheduleEvent.series.startDate.slice(0, 10);
  eventPayload.scheduleEvent.series.endDate = eventPayload.scheduleEvent.series
    .occurrences
    ? ''
    : eventPayload.scheduleEvent.series.endDate.slice(0, 10);
  delete eventPayload.patientsInfo;
  delete eventPayload.patientStatus;
  return eventPayload;
};

export const updateAppointment = (newEvent: any) => {
  const eventPayload = prepareSeriesData(newEvent, true);
  return APIHandler.AxiosInstance.post(
    ApiUrl.UPDATE_SCHEDULE_EVENT,
    eventPayload,
    { withCredentials: true }
  );
};

interface IPatchAppt {
  note: string;
  appointmentReasonId: number;
}

export const patchAppointments = async (
  data: Partial<IPatchAppt> & {
    id: number;
    patientId: number;
  }
): Promise<IAppointment | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${commonApiUrl.APPOINTMENTS}/${data.id}`,
      data,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      return response.data.result;
    }
    return;
  } catch (e) {
    console.log(e);
    return;
  }
};

export const updateWaitingItem = async (data: any) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.UPDATE_WAITING_LIST,
      data,
      {
        withCredentials: true
      }
    );
    return response.data;
  } catch (e) {}
};

export const updateWaitingListItem = (
  event: any,
  patient: any,
  status: any,
  waitingList: any
) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.UPDATE_SCHEDULE_EVENT,
    {
      slicesToUpdate: [],
      scheduleEvents: [
        {
          ...event,
          patient: patient,
          status: status,
          waitingList: waitingList
        }
      ],
      dts: null
    },
    { withCredentials: true }
  );
};

export const updateBlock = (newEvent: any) => {
  const eventPayload = prepareSeriesData(newEvent, true);
  return APIHandler.AxiosInstance.post(
    ApiUrl.UPDATE_SCHEDULE_EVENT,
    eventPayload,
    { withCredentials: true }
  );
};

export const moveGroupWaitingList = async (data: any) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.MOVE_GROUP_WAITINGLIST,
      {
        ...data,
        dts: new Date()
      },
      { withCredentials: true, interceptError: true }
    );
    if (response.data.success) {
      return response.data.result;
    }
    return;
  } catch (e) {
    return;
  }
};

export const moveEvent = (moveEventPayLoad: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.MOVE_SCHEDULE_EVENT,
    {
      ...moveEventPayLoad
    },
    { withCredentials: true }
  );
};

export const updateAppointmentStatus = (payload: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.UPDATE_APPT_STATUS,
    {
      ...payload
    },
    { withCredentials: true }
  );
};

export const reorderColumns = (
  reorder: any,
  startIndex: any,
  endIndex: any
) => {
  return {
    type: REORDER_COLUMNS,
    payload: {
      reorder: reorder,
      startIndex: startIndex,
      endIndex: endIndex
    }
  };
};
//review
export const removeDraggingItem = () => {
  return {
    type: REMOVE_DRAGGING_ITEM
  };
};

export const updateAppointmentHistory = (history: any) => {
  return {
    type: UPDATE_APPOINTMENT_HISTORY,
    payload: {
      history
    }
  };
};

export const updateAppointmentLog = (log: any) => {
  return {
    type: UPDATE_APPOINTMENT_LOG,
    payload: {
      log
    }
  };
};

export const getScheduleReasons = (clinicCodes: string[], evenType: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_SCHEDULE_REASONS,
    {
      clinicCodes,
      eventType: evenType,
      includeInactive: '1'
    },
    { withCredentials: true }
  );
};

export const getGroupOfReasons = (clinicCodes: string[], evenType: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_GROUP_OF_REASONS,
    {
      clinicCodes,
      eventType: evenType,
      includeInactive: '1'
    },
    { withCredentials: true }
  );
};

export const getPatientHistory = async (
  subjectId: number | string,
  params = {
    includeCancellation: true,
    includeFuture: true,
    includeWaitingList: true
  }
): Promise<undefined | IScheduleEvent[]> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_APPOINTMENT_HX,
      {
        subjectId,
        ...params
      },
      { withCredentials: true }
    );
    if (response.data.success) {
      return response.data.result;
    }
    return;
  } catch (e) {
    return;
  }
};

export const getAppointmentHistory = (
  subjectId: any,
  includeCancellation: boolean = true,
  includeFuture: boolean = true,
  includeWaitingList: boolean = true
) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_APPOINTMENT_HISTORY,
    {
      subjectId,
      includeCancellation: includeCancellation,
      includeFuture: includeFuture,
      includeWaitingList: includeWaitingList
    },
    { withCredentials: true }
  );
};

interface IAppointmentLogReguest {
  subjectId?: any;
  appointmentId: any;
  scheduleEventTypeId?: number;
}

export const getAppointmentLog = (data: IAppointmentLogReguest) => {
  const { subjectId, appointmentId, scheduleEventTypeId } = data;
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_APPOINTMENT_LOG,
    {
      subjectId,
      appointmentId,
      scheduleEventTypeId
    },
    { withCredentials: true }
  );
};

export const getNextAvailableEvents = (schedulingPayload: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_NEXT_AVAILABLE_EVENTS,
    {
      ...schedulingPayload
    },
    { withCredentials: true }
  );
};

export const setSchedulerSubtitle = (dateString: string) => {
  return {
    type: SET_SCHEDULER_ACTIVITY_SUBTITLE,
    payload: {
      dateString
    }
  };
};

export const getSubjectSummary = (subjectId: number) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_SUBJECT_SUMMARY,
    {
      subjectId
    },
    { withCredentials: true }
  );
};

export const updateSubjectSummary = (reqTime: number, subjectSummary: {}) => {
  return {
    type: UPDATE_SUBJECT_SUMMARY,
    payload: {
      reqTime,
      subjectSummary
    }
  };
};

export const getSubjectPreferenceFields = (
  subjectId: number,
  patientFieldOrder: any[]
) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_SUBJECT_PREFERENCE_FIELDS,
    {
      subjectId,
      patientFieldOrder
    },
    { withCredentials: true }
  );
};

export const updateSubjectPreferenceFields = (
  reqTime: number,
  subjectPreferenceFields: any[]
) => {
  return {
    type: UPDATE_SUBJECT_PREFERENCE_FIELDS,
    payload: {
      reqTime,
      subjectPreferenceFields
    }
  };
};

export const getSubjectSearch = (
  searchString: any,
  current: any,
  sortBy?: string,
  count?: number,
  includeInactive?: any
) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_SUBJECT_SEARCH,
    {
      searchString: searchString,
      count: count === 0 ? 0 : 20,
      startIndex: current * 20,
      searchField: [
        'lastName',
        'firstName',
        'patientId',
        'nickName',
        'otherId'
      ],
      sortby: sortBy,
      includeInactive: includeInactive === true ? includeInactive : false
    },
    { withCredentials: true }
  );
};

export const getFormsFromClinic = (appointmentId: any, clinicId: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_FORMS_FROM_CLINICS,
    {
      appointmentId: appointmentId,
      clinicId: clinicId
    },
    { withCredentials: true }
  );
};

export const updateLoader = (payload: boolean) => {
  return {
    type: UPDATE_LOADER,
    payload
  };
};

export const updateSubjectSearch = (reqTime: number, subjects: any[]) => {
  return {
    type: UPDATE_SUBJECT_SEARCH,
    payload: {
      reqTime,
      subjects
    }
  };
};

export const createScheduleEvent = (newEvent: any) => {
  const eventPayload = prepareSeriesData(newEvent, false);
  return APIHandler.AxiosInstance.post(
    ApiUrl.CREATE_SCHEDULE_EVENT,
    eventPayload,
    { withCredentials: true }
  );
};

export const getPatientsInCategory = (categories: any) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_PATIENTS_IN_CATEGORY,
    {
      categories: [categories]
    },
    { withCredentials: true }
  );
};

export const conflictsCheckForRecurring = (newEventPayLoad: any) => {
  var eventPayload = _.clone(newEventPayLoad);
  eventPayload.scheduleEvent.series.startDate =
    eventPayload.scheduleEvent.series.startDate.slice(0, 10);
  eventPayload.scheduleEvent.series.endDate =
    eventPayload.scheduleEvent.series.endDate.slice(0, 10);
  delete eventPayload.patientsInfo;
  delete eventPayload.patientStatus;
  delete eventPayload.conflictsResponses;
  return APIHandler.AxiosInstance.post(
    ApiUrl.CONFLICT_CHECK_FOR_SERIES_EVENT,
    eventPayload,
    { withCredentials: true }
  );
};

export const conflictsCheckForEvents = (newEventPayLoad: any) => {
  const eventPayload = _.clone(newEventPayLoad);
  eventPayload.appointmentSeries.startDate =
    eventPayload.appointmentSeries.startDate.slice(0, 10);
  eventPayload.appointmentSeries.endDate =
    eventPayload.appointmentSeries.endDate.slice(0, 10);
  eventPayload.appointmentSeries.startTime =
    eventPayload.appointmentSeries.startTime;
  eventPayload.appointmentSeries.endTime =
    eventPayload.appointmentSeries.endDate.slice(0, 10) +
    eventPayload.appointmentSeries.endTime.slice(10, 19);
  delete eventPayload.patientsInfo;
  delete eventPayload.updateSeries;
  delete eventPayload.eventMembers;
  delete eventPayload.providerCodes;
  delete eventPayload.scheduleEvent;
  delete eventPayload.patientStatus;
  delete eventPayload.conflictsResponses;
  return APIHandler.AxiosInstance.post(
    ApiUrl.CONFLICT_CHECK_FOR_EVENTS,
    eventPayload,
    { withCredentials: true }
  );
};

export const addSubjectToWaitingList = (
  reason: any,
  patients: any,
  waitingList: any,
  clinic: any,
  currentDateRange: any,
  comment: string,
  tags: number[],
  providerIds: number[]
) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.CREATE_SCHEDULE_EVENT,
    {
      dts: new Date(),
      scheduleEvent: {
        appointmentBlockId: 0,
        appointmentId: 0,
        maxParticipants: null,
        visitCount: null,
        groupAppointment: false,
        comment,
        tags,
        endTime: moment(currentDateRange[1]).format('YYYY-MM-DDT13:00:00'),
        page: 1,
        providerCode: null,
        room: null,
        facilityCode: null,
        clinicCode: clinic && clinic.value,
        clinicName: clinic && clinic.label,
        userCode: null,
        categoryCode: null,
        reason: reason,
        scheduleEventDateTime: moment(currentDateRange[1]).format(
          'YYYY-MM-DDT12:00:00'
        ),
        series: null,
        scheduleEventTypeId: 5,
        sequenceId: 0,
        waitingList: waitingList
      },
      waitingListProviderIds: providerIds.length > 0 ? providerIds : undefined,
      patients: [patients[0].subjectId],
      providerCodes: null
    },
    { withCredentials: true }
  );
};

export const getCategoryList = () => {
  return APIHandler.AxiosInstance.post(ApiUrl.GET_CATEGORY_LIST, null, {
    withCredentials: true
  });
};

export const setWaitingListItem = (id: string) => {
  return {
    type: EDIT_WAITING_LIST_ITEM,
    payload: id
  };
};

export const getPatientsInsurances = async (
  patientIds: (number | string)[]
): Promise<Record<string, IPatientInsurance> | undefined> => {
  try {
    const res = await APIHandler.AxiosInstance.get(
      GlobalApiUrl.PATIENT_INSURANCES,
      {
        withCredentials: true,
        params: {
          patientIds
        }
      }
    );
    if (res.data.success) {
      const data = res.data.result.summary;
      return data;
    }
    return;
  } catch (e) {
    console.log(e);
    return;
  }
};
