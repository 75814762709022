import _ from "lodash";
import { SET_SCHEDULE_SLICES, UPDATE_SCHEDULE_SLICES, REMOVE_SCHEDULE_SLICE } from './ScheduleSlicesActions';

const initState: IState = {
  reqTime: 0
};

interface IState { reqTime?: number }

interface IAction {
  type: string;
  payload: any;
}

export const ScheduleSlicesReducer = (state: IState = initState, action: IAction) => {
  switch (action.type) {
    case REMOVE_SCHEDULE_SLICE:
      //Remove the key from the state
      //newState.remove(action.payload);
      return state;
    case SET_SCHEDULE_SLICES:
      if (state.reqTime && state.reqTime <= action.payload.reqTime) {
        //Replace the state with the payload
        state = { ...action.payload };
      }
      return state;
    case UPDATE_SCHEDULE_SLICES: {
      //Merge the state with the payload
      // This code preventing rerendering all columns instead of changed columns
      Object.entries(action.payload).forEach(([key, value]) => {
        if (!_.isEqual(state[key], value)) {
          state[key] = value
        }
      })
      return state;
    }
    default:
      return state;
  }
};
