import _ from "lodash";
import {
  UPDATE_CLINICS,
  UPDATE_WAITING_GROUPS,
  UPDATE_WAITING_GROUP_PATIENTS,
  UPDATE_SUBJECT_SUMMARY,
  UPDATE_SUBJECT_SEARCH,
  UPDATE_APPOINTMENT_HISTORY,
  UPDATE_APPOINTMENT_LOG,
  UPDATE_SUBJECT_PREFERENCE_FIELDS,
  SET_SCHEDULER_ACTIVITY_SUBTITLE,
  EDIT_WAITING_LIST_ITEM,
  UPDATE_LOADER,
  REMOVE_DRAGGING_ITEM, REORDER_COLUMNS,
} from "../../ScheduleActions/ScheduleDetailActions/ScheduleDetailAction";
import { defaultScheduleTimeProps } from "./initialData";
import { IStateReducer, IAction, IOrderValue } from './types'

const prefix = 'SCHEDULE_DETAILS_'

export const actionsTypes = {
  SET_FILTERS: `${prefix}SET_FILTERS`,
  SET_FILTERED_SLICES: `${prefix}SET_FILTERED_SLICES`,
  SET_DRAG_PROGRESS: `${prefix}SET_DRAG_PROGRESS`,
}

export const defaultPageLength = 8;

export const defaultProvidersColumnsOrder: IOrderValue = {
  dayColumnsIds: [],
  weekClinicCodes: [],
  weekProvidersCodes: []
}

const initialState: IStateReducer = {
  clinics: [],
  isLoading: true,
  providers: [],
  lastNextAvailableEventsReqTime: 0,
  nextEvents: [],
  lastWaitingListReqTime: 0,
  waitingGroups: [],
  waitingGroupPatients: [],
  lastSubjectSummaryReqTime: 0,
  subjectSummary: {},
  lastSubjectSearchReqTime: 0,
  subjects: [],
  draggingItem: {},
  scheduleEvents: {},
  eventInfo: {},
  history: [],
  log: [],
  subjectPreferenceFields: [],
  lastSubjectPreferenceFieldsReqTime: 0,
  lastnewWaitingListReqTime: 0,
  schedulerActivitySubtitle: "",
  idWaitingListItem: "",
  reorderProps: {
    reorder: false,
    startIndex: null,
    endIndex: null,
  },
  filters: {
    isShowingPatientName: true,
    isShowingReservationLabel: true,
    currentView: 'day',
    currentClinics: [],
    currentProviders: [],
    currentDateRange: [new Date(), new Date()],
    weekFilter: '5days',
    scheduleTimeProps: _.cloneDeep(defaultScheduleTimeProps),
    zoom: 1,
    showCancellations: false,
    openRightBar: true,
    pageNumber: 0,
    pageLength: defaultPageLength,
    favoriteId: null,
    initialLoadId: null,
    reservationsOnly: false,
    reservationLabelOpacity: true
  },
  filteredSlices: [],
  dragInProgress: false
};

const ScheduleDetailsReducer = (state: IStateReducer = initialState, action: IAction): IStateReducer => {
  switch (action.type) {
    case actionsTypes.SET_DRAG_PROGRESS:
      return {
        ...state,
        dragInProgress: action.payload
      }
    case actionsTypes.SET_FILTERED_SLICES:
      return {
        ...state,
        filteredSlices: [...action.payload]
      }
    case actionsTypes.SET_FILTERS:
      state.filters = {
        ...state.filters,
        ...action.payload
      }
      return state
    case UPDATE_CLINICS: {
      return { ...state, clinics: action.payload }
    }
    case UPDATE_LOADER:
      return { ...state, isLoading: action.payload }
    case UPDATE_WAITING_GROUPS: {
      state.waitingGroups = action.payload.waitingGroups
      state.lastWaitingListReqTime = action.payload.reqTime
      return state
    }
    case UPDATE_WAITING_GROUP_PATIENTS: {
      state.waitingGroupPatients = action.payload.waitingGroupPatients
      state.lastWaitingListReqTime = action.payload.reqTime
      return state
    }
    case UPDATE_SUBJECT_SUMMARY:
      return {
        ...state,
        subjectSummary: action.payload.subjectSummary,
        lastSubjectSummaryReqTime: action.payload.reqTime
      }
    case REORDER_COLUMNS:
      return {
        ...state,
        reorderProps: {
          reorder: action.payload.reorder,
          startIndex: action.payload.startIndex,
          endIndex: action.payload.endIndex,
        }
      };
    case UPDATE_SUBJECT_PREFERENCE_FIELDS:
      return {
        ...state,
        subjectPreferenceFields: action.payload.subjectPreferenceFields,
        lastSubjectPreferenceFieldsReqTime: action.payload.reqTime
      };
    //deprecate
    case REMOVE_DRAGGING_ITEM:
      return {
        ...state,
        draggingItem: { ...initialState.draggingItem },
        eventInfo: { ...initialState.eventInfo }
      };
    case UPDATE_SUBJECT_SEARCH:
      return {
        ...state,
        subjects: action.payload.subjects,
        lastSubjectSearchReqTime: action.payload.reqTime
      };
    case UPDATE_APPOINTMENT_HISTORY:
      return {
        ...state,
        history: action.payload.history
      };
    case UPDATE_APPOINTMENT_LOG:
      return {
        ...state,
        log: action.payload.log
      };
    case SET_SCHEDULER_ACTIVITY_SUBTITLE:
      return {
        ...state,
        schedulerActivitySubtitle: action.payload.dateString
      };
    case EDIT_WAITING_LIST_ITEM:
      return {
        ...state,
        idWaitingListItem: action.payload
      };
    default:
      return state
  }
};

export default ScheduleDetailsReducer;