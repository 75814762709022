// export var patientTerm = {
//     term:   "Client"
// };
import {
  uppercaseFirstLatter,
  lowercaseFirstLatter
} from 'src/Framework/Shared/Shared';
import store from 'src/store';

export const getPatientTerm = (str: string) => {
  let term = store.getState().user.preferences.patientTerm?.value || 'Client'

  //this should do a replace of the patient term from subject/client/patient to the correct patient term,
  //it should keep the same case as the original string
  str = str.replace(/Student/g, uppercaseFirstLatter(term) ?? 'Client');
  str = str.replace(/student/g, lowercaseFirstLatter(term) ?? 'client');
  str = str.replace(/STUDENT/g, term.toUpperCase() ?? 'CLIENT');
  str = str.replace(/Subject/g, uppercaseFirstLatter(term) ?? 'Client');
  str = str.replace(/subject/g, lowercaseFirstLatter(term) ?? 'client');
  str = str.replace(/SUBJECT/g, term.toUpperCase() ?? 'CLIENT');
  str = str.replace(/Patient/g, uppercaseFirstLatter(term) ?? 'Client');
  str = str.replace(/patient/g, lowercaseFirstLatter(term) ?? 'client');
  str = str.replace(/PATIENT/g, term.toUpperCase() ?? 'CLIENT');
  str = str.replace(/Client/g, uppercaseFirstLatter(term) ?? 'Client');
  str = str.replace(/client/g, lowercaseFirstLatter(term) ?? 'client');
  str = str.replace(/CLIENT/g, term.toUpperCase() ?? 'CLIENT');
  return str;
};

export const getStudentStatusTerm = () => 'Campus Status';
