import React from 'react';

import { Alert } from 'src/Framework/Common/Notification';
import { hashParser } from 'src/Framework/util/helps';
import { checkResolution } from 'src/Framework/util/resolution-utils';
import history from 'src/history';

import { IOpenIdConnectQueryParam, ISSOHash, ISSOQueryParams } from './types';
import { SSOTypes } from '../LoginPage/store/types';
import { SSOOpenIdConnect, SSOTokenChecker } from '../LoginPage/store/Actions';

const LazyAzureAd = React.lazy(() => import('./AzureAd'));
const LazyOkta = React.lazy(() => import('./okta'));
const LazyInCommon = React.lazy(() => import('./InCommon'));
const LazyMedicat = React.lazy(() => import('./Medicat'));
const LazyOpenIdConnect = React.lazy(() => import('./OpenIdConnect'));

export const SSO_DATA = {
  azureAd: {
    component: LazyAzureAd,
    path: '/sso/azureAd'
  },
  okta: {
    component: LazyOkta,
    path: '/sso/okta'
  },
  inCommon: {
    component: LazyInCommon,
    path: '/sso/inCommon'
  },
  medicat: {
    component: LazyMedicat,
    path: '/sso/medicat'
  },
  [SSOTypes.openIdConnect]: {
    component: LazyOpenIdConnect,
    path: `/sso/${SSOTypes.openIdConnect}`
  }
};

export const errorMessageDisplay = (hashData: any) => {
  const errorData = hashData?.errorMsg || hashData?.error_description;
  if (errorData) {
    Alert('error', 'Error', decodeURIComponent(errorData), 0);
  }
};

export const initLogic = async (type: SSOTypes) => {
  if (type === SSOTypes.openIdConnect) {
    const searchParams = new URLSearchParams(window.location.search)
    const params: IOpenIdConnectQueryParam = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    const hashData: ISSOHash | null = window.location.hash
      ? hashParser(window.location.hash)
      : null;
    errorMessageDisplay(hashData);
    if (params.code && params.state) {
      const res = await SSOOpenIdConnect({ params })
      if (res) {
        checkResolution();
        return;
      }
    }
    history.push('/login');
    return
  }

  const searchParams = new URLSearchParams(window.location.search)
  const params: ISSOQueryParams = {}
  searchParams.forEach((value, key) => {
    params[key] = value
  })

  const hashData: ISSOHash | null = window.location.hash
    ? hashParser(window.location.hash)
    : null;
  errorMessageDisplay(hashData);
  const id_token = hashData?.id_token;
  if (hashData && id_token) {
    const res = await SSOTokenChecker({
      type,
      id_token,
      hashData,
      params
    });
    if (res) {
      checkResolution();
      return;
    }
  }
  history.push('/login');
};
