import axios from 'axios'
import { AppUrl } from 'src/Framework/Common/AppUrl';
import { ApiUrl } from "../../../Framework/Common/ApiUrl";
import { APIHandler, configData } from "../../../Framework/Communication/ServerProxy";
import { navbarVisible, } from 'src/App/SideNavBar/Store/SideNavBarAction';
import { getPreference } from 'src/Activities/Compliance/Store/ComplianceActions';
import { setClinicUser } from '../ClinicSelector';

import { actionTypes } from '.'
import * as AccessToken from './AccessToken'
import { IAuthResponseDto, ILoginRequest, IChallengeRequest, SSOTypes } from './types';

import { store } from 'src';
import { getCurrentUserPreference } from 'src/App/Preferences/Actions';
import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';

import history from '../../../history'
import { IOpenIdConnectQueryParam, ISSOHash, ISSOQueryParams } from 'src/App/SSO/types';

export const microsoftRequest = async (tenantID: string) => {
  try {
    const response = await APIHandler.AxiosInstance.get(`${ApiUrl.SSO}/${tenantID}`)
    if (response.data.url) {
      window.location.href = response.data.url
    }
  } catch (e) {
    console.log({ e })
  }
}

const afterSSOResponse = async (params: ISSOQueryParams) => {
  const pref = await getCurrentUserPreference();
  if (params.afterLoginUrl) {
    const link = `/${params.afterLoginUrl}`;
    history.push(link);
    loadActivityTab(link, store.dispatch);
  } else {
    const link = pref?.userEntryScreen?.value || '/home';
    history.push(link);
    loadActivityTab(link, store.dispatch);
  }
}

export const SSOOpenIdConnect = async ({ params }: { params: IOpenIdConnectQueryParam }): Promise<IAuthResponseDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(`${ApiUrl.SSO}/login/${SSOTypes.openIdConnect}`, params)
    if (response.data) {
      if (response.data.success) {
        const data: IAuthResponseDto = response.data
        AccessToken.set(data)
        if (data.userInfo) {
          setDataAfterLogin(data.userInfo)
        }
        await afterSSOResponse(params)
        return data
      }
    }
    return
  } catch (e) {
    console.log({ e })
    return
  } finally {
  }
}

interface ISSOTokenCheckerParams {
  type: SSOTypes,
  id_token: string,
  hashData: ISSOHash,
  params: ISSOQueryParams
}

export const SSOTokenChecker = async ({ type, id_token, hashData, params }: ISSOTokenCheckerParams): Promise<IAuthResponseDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(`${ApiUrl.SSO}/login/${type}`, {
      IdToken: id_token
    })
    if (response.data) {
      if (response.data.success) {
        const data: IAuthResponseDto = response.data
        AccessToken.set(data)
        if (data.userInfo) {
          setDataAfterLogin(data.userInfo)
        }
        await afterSSOResponse(params)
        return data
      }
    }
    return
  } catch (e) {
    console.log({ e })
    return
  } finally {
  }
}

export const clearingData = () => {
  localStorage.clear();
  delete APIHandler.AxiosInstance.defaults.headers.Authorization;
}

export const authV2Login = async (body: ILoginRequest): Promise<IAuthResponseDto | undefined> => {
  try {
    clearingData()
    const response = await APIHandler.AxiosInstance.post(`${ApiUrl.AUTH_V2}/login`, body)
    if (response.data) {
      if (response.data.success) {
        const data: IAuthResponseDto = response.data
        AccessToken.set(data)
        if (data.userInfo) {
          setDataAfterLogin(data.userInfo)
        }
        return data
      }
    }
    return
  } catch (e) {
    console.log({ e })
    return
  } finally {
  }
}

export const authV2Refresh = async (body: { refreshToken: string }): Promise<IAuthResponseDto | undefined> => {
  try {
    const response = await axios.post(`${configData?.baseUrl}auth/v2/refresh`, body)
    if (response.data) {
      if (response.data.success) {
        const data: IAuthResponseDto = response.data
        AccessToken.set(data)
        if (data.userInfo) {
          setDataAfterLogin(data.userInfo)
        }
        return data
      } else {
        logout()
      }
    }
    return
  } catch (e) {
    console.log({ e })
    return
  } finally {
  }
}

export const postChallenge = async (body: IChallengeRequest): Promise<IAuthResponseDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(`${ApiUrl.AUTH_V2}/challenge`, body)
    if (response.data) {
      if (response.data) {
        const data: IAuthResponseDto = response.data
        if (data.success) {
          AccessToken.set(data)
          if (data.userInfo) {
            setDataAfterLogin(data.userInfo)
          }
        }
        return data
      }
    }
    return
  } catch (e) {
    console.log({ e })
    return
  } finally {
  }
}


export const userLogin = (result: any) => {
  return {
    type: actionTypes.USER_LOGIN,
    payload: result,
  };
};

export const addPermissions = (result: any) => {
  return {
    type: actionTypes.GET_PERMISSION,
    payload: result,
  };
};

export const logoutWithError = (error: string, encode = true) => {
  localStorage.clear();
  window.location.href = `${AppUrl.SESSION_LOGIN}?error=${encode ? encodeURI(error) : error}`;
}

export const logoutSession = () => {
  localStorage.clear();
  window.location.href = AppUrl.SESSION_LOGIN;
}

export const logout = () => {
  localStorage.clear();
  window.location.href = '/';
}

export const userLogoff = (): any => {
  return APIHandler.AxiosInstance.post(ApiUrl.USER_LOGOFF, null, { withCredentials: true })
    .then(response => {
    })
    .catch(error => {
      console.log(error);
    });
};

export const expiryCheck = (error: any): any => {
  return (dispatch: any) => {
    if (error.request && error.request.status === 401) {
      return
    }
  };
};

export const setTenantId = (tenantId: string) => {
  localStorage.setItem("tenantId", tenantId)
}

const setDataAfterLogin = (data: any) => {
  localStorage.setItem("user", data.userName)
  setTenantId(data.user.tenantId)
  localStorage.setItem("userName", data.user.name)
  localStorage.setItem("userId", data.userId)
  store.dispatch(userLogin(data));
  store.dispatch(navbarVisible());
}

const setLoginData = (data: any, dispatch: any = store.dispatch, callBack?: any) => {
  if (data.success) {

    let currentClinic = data.clinics.find((m: any) => m.id === data.clinicId);
    if (!currentClinic && data.clinics.length > 0) {
      currentClinic = data.clinics[0];
    }

    localStorage.setItem("user", data.userName)
    setTenantId(data.user.tenantId)
    localStorage.setItem("userName", data.user.name)
    localStorage.setItem("userId", data.userId)
    setClinicUser(currentClinic, data.userId, dispatch);

    let preferenceData = {
      userId: data.userId,
      preferences: ["ClinicCode", "CohortFilter", "AddFactFilter", "PatientFieldOrder", "ApptColorArrived", "ApptColorReady", "ApptColorAdmitted", "ApptColorDischarge", "ApptColorLate", "SchedulerViewType", "SchedulerDayViewInfo", "SchedulerWorkWeekViewInfo", "SchedulerWeekProviderViewInfo"]
    }

    getPreference(preferenceData)
      .then(response => {
        let prefData: any = response.data;
        if (prefData.success) {
          const summary: any[] = prefData.result.summary;
          if (summary && summary.length > 0) {
            const clinicPref = summary.find((pref: any) => pref.name === "ClinicCode");
            const cohortPref = summary.find((pref: any) => pref.name === "CohortFilter");
            const factPref = summary.find((pref: any) => pref.name === "AddFactFilter");
            const patientFieldPref = summary.find((pref: any) => pref.name === "PatientFieldOrder");
            let cohortName: string = "";
            let factFilter: string = "";
            let patientFields: any[] = [];
            if (clinicPref && clinicPref.value) {
              currentClinic = data.clinics.find((m: any) => m.id === Number(clinicPref.value)) ?? currentClinic;
            }
            if (!currentClinic) {
              currentClinic = data.clinics[0];
            }
            if (cohortPref && cohortPref.value) {
              cohortName = cohortPref.value;
            }
            if (factPref && factPref.value) {
              factFilter = factPref.value;
            }
            if (currentClinic) {
              setClinicUser(currentClinic, data.userId, dispatch);
              if (callBack) {
                callBack(currentClinic.id, data.error);
              }
            }
            if (patientFieldPref && patientFieldPref.value) {
              patientFields = patientFieldPref.value.split(';');
            }
            localStorage.setItem("cohortName", cohortName);
            localStorage.setItem("factFilter", factFilter);
            localStorage.setItem("patientFields", JSON.stringify(patientFields));
          }
        } else {
          if (callBack) {
            callBack(currentClinic.id, null);
          }
        }
      }).catch(error => {
        if (callBack) {
          callBack(currentClinic.id, null);
        }
      })
    dispatch(userLogin(data));
    dispatch(navbarVisible());
  } else {
    if (callBack) {
      callBack(data.clinicId, data.error);
    }
  }
};

export const internalLogin = async (
  token: any,
  dispatch: any,
  onSuccess?: any,
  onError?: any
) => {
  return APIHandler.AxiosInstance.get(ApiUrl.INTERNAL_LOGIN, {
    withCredentials: true,
    headers: { 'Authorization': `Aes ${token}` }
  })
    .then(response => {
      let data: any = response.data;
      setLoginData(data, dispatch, onSuccess);
    })
    .catch(() => {
      onError();
    });
};

export const getPermissions = async (
  clinicId: number,
  callBack?: any,
): Promise<any> => {
  let clinicDetails = {
    clinicId: clinicId,
  };

  try {
    const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_PERMISSION, clinicDetails, { withCredentials: true })
    const data: any = response.data;
    if (data.success) {
      store.dispatch(addPermissions(data.result));
      if (callBack) {
        callBack(data.result);
      }
    }
  } catch (error) {
    console.log(error);
    if (callBack) {
      callBack()
    }

  };
};