import store from 'src/store'

import { actionsTypes } from '../index'

import { IFilter } from '../types'
import { GenericIdsTypes } from '../../utils'

export const setFilter = (payload: Partial<IFilter>) => {
    store.dispatch({
        type: actionsTypes.SET_FILTER,
        payload
    })
}

export const setSummary = (payload: any, genericTypeId: GenericIdsTypes) => {
    store.dispatch({
        type: actionsTypes.SET_SUMMARY,
        payload,
        genericTypeId
    })
}

export const onCreateUpdateItem = (payload: any, genericTypeId: GenericIdsTypes) => {
    const summary = { ...store.getState().adminPanel.groupTypes.summary[genericTypeId] || {} }
    summary[payload.id] = payload
    setSummary(summary, genericTypeId)
}