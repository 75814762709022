import React, { FC } from 'react';

import { ReactComponent as Logo } from 'src/Framework/Common/Svg/new-logo.svg';

import {
  Container,
  Company,
  Description,
  Message,
  MessageContainer
} from './styled';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  return (
    <Container>
      <MessageContainer>
        <Company>
          <Logo className={'logo-image'} fill={'white'} />
        </Company>
        <Message>SORRY</Message>
        <Description>Application not support portrait mode</Description>
      </MessageContainer>
    </Container>
  );
};

export default React.memo(Component);
