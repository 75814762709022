import { TooltipPropsWithTitle } from 'antd/es/tooltip';
import React, { FC, useRef, useEffect, useState } from 'react';

import Tooltip from 'src/Framework/Controls/Tooltip';

interface IProps extends Omit<Partial<TooltipPropsWithTitle>, 'children'> {
  children: string | React.ReactNode;
  maxWidth?: number | string;
  height?: number | string;
  disabled?: boolean;
}

/**
 * {@link EllipsisText}
 ** This component will give you ability to add tooltip where its needed
 ** When text over the container you will have ellipsis text with tooltip
 ** Also component has ResizeObserver listener that allows to add or remove tooltip when component change his size
 */

const EllipsisText: FC<IProps> = ({
  children,
  maxWidth,
  height,
  disabled
}: IProps) => {
  const [isEllipsis, setIsEllipsis] = useState(false);
  const resizeRef = useRef<ResizeObserver>();
  const textRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const e = textRef.current;
    if (e) {
      const check = () => {
        const isEllipsis = e.offsetWidth < e.scrollWidth;
        setIsEllipsis(isEllipsis);
      };
      check();
      if (resizeRef.current) {
        resizeRef.current?.unobserve(e);
        resizeRef.current?.disconnect();
      }
      resizeRef.current = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          check();
        });
      });
      resizeRef.current?.observe(e);
      return () => {
        resizeRef.current?.unobserve(e);
        resizeRef.current?.disconnect();
      };
    }
    return () => {};
  }, [children]);
  return (
    <Tooltip
      title={disabled ? undefined : isEllipsis ? children : ''}
      mouseEnterDelay={0.5}
    >
      <div
        style={{
          width: '100%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: maxWidth,
          height: height
        }}
        ref={textRef}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default EllipsisText;
