import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { defaultInfinityProps } from '.';
import { IProps as IMainProps } from './types';
import NoData from './NoData';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller';

import RenderTable from './RenderTable';

interface IProps extends IMainProps {
  pageNumber: number;
  pageLength: number;
  uniqueIdHeader: string;
}

const Component: FC<IProps> = (props: IProps) => {
  const {
    data,
    emptyComponent,
    disableEmptyContainerMargin,
    infinity,
    containerId,
    infinityContainerProps,
    infinityProps,
    dataSource,
    uniqueIdHeader
  } = props;
  const [headerWidth, setHeaderWidthWidth] = useState(0);
  const resizeRef = useRef<ResizeObserver>();

  useEffect(() => {
    const element = document.getElementById(uniqueIdHeader);
    if (element) {
      const check = () => {
        setHeaderWidthWidth(element.scrollWidth);
      };
      check();
      if (resizeRef.current) {
        resizeRef.current?.unobserve(element);
        resizeRef.current?.disconnect();
      }
      resizeRef.current = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          check();
        });
      });
      resizeRef.current?.observe(element);
      return () => {
        resizeRef.current?.unobserve(element);
        resizeRef.current?.disconnect();
      };
    }
    return () => {};
  }, []);
  return (
    <>
      {(dataSource?.length === 0 || data?.length === 0) &&
        (emptyComponent ? (
          emptyComponent
        ) : (
          <div
            className={classNames({
              'empty-container': true,
              disableMargin: disableEmptyContainerMargin
            })}
            style={{
              minWidth: headerWidth ? `calc(${headerWidth}px - 0.7rem)` : '100%'
            }}
          >
            <NoData />
          </div>
        ))}
      {dataSource?.length !== 0 &&
        data?.length !== 0 &&
        (infinity ? (
          <div
            id={containerId}
            className="infinity-container"
            {...infinityContainerProps}
          >
            <div>
              <InfiniteScroll {...defaultInfinityProps} {...infinityProps}>
                <RenderTable {...props} headerWidth={headerWidth} />
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <RenderTable {...props} headerWidth={headerWidth} />
        ))}
    </>
  );
};

export default React.memo(Component);
