import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Container, Text } from './styled';
import { ITablePagination } from '../types';
import { Col, Row } from 'antd';
import SelectContainer from '../../SelectContainer';
import { usePagination, DOTS } from './Hooks/usePagination';
import classNames from 'classnames';

interface IProps {
  pagination: ITablePagination;
  hideBorder?: boolean;
}

const minContainerWidth = 715;
const defaultSiblingCount = 4;
const defaultNearSiblingsCount = 3;

const Component: FC<IProps> = ({ pagination, hideBorder }: IProps) => {
  const [siblingCount, setSiblingCount] = useState(defaultSiblingCount);
  const [nearSiblingsCount, setNearSiblingsCount] = useState(
    defaultNearSiblingsCount
  );
  const currentStates = useRef({
    siblingCount,
    nearSiblingsCount
  });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const resizeRef = useRef<ResizeObserver>();
  const { total, pageLength, startIndex, onChangePage } = pagination;
  const currentPage = startIndex / pageLength + 1;
  const totalCount = Math.ceil(total / pageLength);

  const paginationRange = usePagination({
    currentPage,
    totalCount: total,
    siblingCount,
    pageSize: pageLength,
    nearSiblingsCount
  });

  const onNext = () => {
    onChangePage(currentPage);
  };

  const onPrevious = () => {
    onChangePage(currentPage - 2);
  };

  useLayoutEffect(() => {
    currentStates.current = {
      siblingCount,
      nearSiblingsCount
    };
  });

  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      const check = () => {
        const clientWidth = element.clientWidth;
        if (clientWidth < minContainerWidth) {
          const calc = Math.ceil((minContainerWidth - clientWidth) / 10);
          let siblingCount = defaultSiblingCount;
          let nearSiblingsCount = defaultNearSiblingsCount;
          if (calc >= 10) {
            if (calc >= 20) {
              nearSiblingsCount = 1;
            }
            siblingCount = 1;
          } else {
            if (calc >= 5) {
              siblingCount = 2;
            } else {
              if (calc >= 1) {
                siblingCount = 3;
              }
            }
          }
          // Prevent unnessassary rerender
          if (currentStates.current.nearSiblingsCount !== nearSiblingsCount) {
            setNearSiblingsCount(nearSiblingsCount);
          }
          if (currentStates.current.siblingCount !== siblingCount) {
            setSiblingCount(siblingCount);
          }
        }
      };
      check();
      if (resizeRef.current) {
        resizeRef.current?.unobserve(element);
        resizeRef.current?.disconnect();
      }
      resizeRef.current = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          check();
        });
      });
      resizeRef.current?.observe(element);
      return () => {
        resizeRef.current?.unobserve(element);
        resizeRef.current?.disconnect();
      };
    }
    return () => {};
  }, [totalCount]);

  if (!paginationRange || totalCount <= 1) {
    return null;
  }
  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Container ref={containerRef} hideBorder={hideBorder}>
      <Row align="middle" gutter={10} justify="space-between" wrap={false}>
        <Col>
          <ul className={classNames('pagination-container')}>
            <li
              className={classNames('pagination-item', {
                disabled: currentPage === 1
              })}
              onClick={onPrevious}
            >
              <div className="arrow left" />
            </li>
            {paginationRange.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return <li className="pagination-item dots">&#8230;</li>;
              }
              return (
                <li
                  className={classNames('pagination-item', {
                    selected: pageNumber === currentPage
                  })}
                  onClick={() => onChangePage(+pageNumber - 1)}
                >
                  {pageNumber}
                </li>
              );
            })}
            <li
              className={classNames('pagination-item', {
                disabled: currentPage === lastPage
              })}
              onClick={onNext}
            >
              <div className="arrow right" />
            </li>
          </ul>
        </Col>
        <Col>
          <Row align="middle" gutter={8} wrap={false}>
            <Col>
              <Text>Page</Text>
            </Col>
            <Col style={{ width: 80 }}>
              <SelectContainer
                label=""
                type="select"
                value={currentPage}
                onChange={(v: any) => {
                  onChangePage(v ? +v - 1 : 0);
                }}
                bottomMargin={false}
                hideLabel={true}
                options={Array.from(
                  { length: totalCount },
                  (_, i) => i + 1
                ).map((v) => ({
                  label: v,
                  value: v
                }))}
              />
            </Col>
            <Col>
              <Text>of {totalCount}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Component);
