import { setPreference, updateCurrentUserPreference } from "src/App/Preferences/Actions";
import { IOrderValue } from "./ScheduleReducers/ScheduleDetailsReducer/types";

export const SET_SCHEDULE_SLICES = "SET_SCHEDULE_SLICES";
export const UPDATE_SCHEDULE_SLICES = "UPDATE_SCHEDULE_SLICES";
export const REMOVE_SCHEDULE_SLICE = "REMOVE_SCHEDULE_SLICE";

export const updateScheduleSlices = (reqTime: number, slices: any) => {
  return {
    type: UPDATE_SCHEDULE_SLICES,
    payload: { ...slices, reqTime },
  };
};
export const setScheduleSlices = (reqTime: number, slices: any) => {
  return {
    type: SET_SCHEDULE_SLICES,
    payload: { ...slices, reqTime },
  };
};


export const onChangeProviderColumnsOrder = async (value: IOrderValue) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 3035,
        userId,
        name: 'ProvidersColumnsOrder',
        value: JSON.stringify(value),
        defaultValue: ''
      }
    ]
  };
  try {
    updateCurrentUserPreference(userPreferenceInfo.preferences);
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      return data
    }
    return
  } catch (error) {
    console.log({ error });
    return
  }
};
