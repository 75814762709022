import React, { FC, useEffect, useState } from 'react';
import { Drawer } from 'antd';

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import Container from './Container';
import { IModalSliderHeader } from './Header';

export interface IModalSliderProps extends IModalSliderHeader {
  isOpen: boolean;
  defaultWidth?: string;
  minWidth?: string;
  children?: React.ReactNode;
  onClose: () => any;
  afterClose?: () => any;
  hideHeader?: boolean;
  id?: string;
}

const ModalSlider: FC<IModalSliderProps> = (props) => {
  const { isOpen, onClose, afterClose } = props;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Drawer
      zIndex={globalStyleVariables.sliderBaseZIndex}
      title={null}
      closable={false}
      destroyOnClose={true}
      width={'auto'}
      onClose={onClose}
      open={open}
      className="modal-slider"
      contentWrapperStyle={{
        zIndex: globalStyleVariables.sliderBodyZIndex
      }}
      maskStyle={{
        backgroundColor: globalStyleVariables.sliderMaskColor
      }}
      afterOpenChange={(open) => {
        if (!open) {
          afterClose && afterClose();
        }
      }}
    >
      <Container {...props} />
    </Drawer>
  );
};

export default React.memo(ModalSlider);
