export enum SSOTypes {
    incommon = 'incommon',
    okta = 'okta',
    azureAd = 'azureAd',
    medicat = 'medicat',
    openIdConnect = 'openIdConnect'
}

export interface IStateReducer {
    response: any,
    permissions: Partial<INavBarPermissions>,
    currentClinic: any,
    errors: any,
    connection: any,
    noteTypes: any
}

interface INavBarPermissions {
    activity: boolean
    admin: boolean
    billing: boolean
    cats: boolean
    compliance: boolean
    dashboard: boolean
    messages: boolean
    orders: boolean
    reports: boolean
    schedule: boolean
    scpm: boolean
    subjects: boolean
    systemsetup: boolean
    usersettings: boolean
    patientheadersettings: boolean
}
export interface IAuthResponseDto {
    accessToken: string
    refreshToken: string
    duoPreauth?: {
        deviceId: string
        deviceName: string
    };
    message: string
    success: boolean
    userInfo?: {
        clinicId: number
        user?: {
            tenantId: string
        }
    }
}

export interface ILoginRequest {
    userName: string
    password: string
}

export interface IChallengeRequest {
    deviceId: string
}

export interface IAction {
    type: string;
    payload: any;
}