import { BucketsList } from "src/Framework/ApiSubscription";
import { IPatchResponse, RequestHelper } from "src/Framework/ApiSubscription/RequestHelper";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { IM1FormRecord } from "../types";
import { setPatientSummary, onCreateUpdatePatientItem, setM1FormsInNote, onCreateUpdateM1FormInNoteItem } from "./Setters";

export const SubscriptionDataM1FormInNote = (PatientId: string | number, noteId: number | string) => ({
    bucket: BucketsList.m1FormRecord,
    filter: `m1FormRecord.SubjectPatientId == ${PatientId} && m1FormRecord.NoteId == ${noteId}`
})

export const requestsM1FormInNote = new RequestHelper<IM1FormRecord, [patientId: string | number, noteId: number | string], [patientId: string | number]>({
    link: ApiUrl.M1FormRecords,
    onCreateUpdateItem: onCreateUpdateM1FormInNoteItem,
    subscription: {
        subscriptionData: SubscriptionDataM1FormInNote,
        setSummary: setM1FormsInNote
    }
})

export const SubscriptionData = (PatientId: string | number) => ({
    bucket: BucketsList.m1FormRecord,
    filter: `m1FormRecord.SubjectPatientId == ${PatientId} && m1FormRecord.NoteId == null`
})

export const SubscriptionDataM1Form = (id: string | number) => ({
    bucket: BucketsList.m1FormRecord,
    filter: `m1FormRecord.Id == ${id}`
})

export const requests = new RequestHelper<IM1FormRecord, [patientId: string | number], [patientId: string | number]>({
    link: ApiUrl.M1FormRecords,
    onCreateUpdateItem: onCreateUpdatePatientItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary: setPatientSummary
    }
})

export const { post, patch, unsubscribe } = requests

export const get = requests.get


export const M1RecordRequests = new RequestHelper<IM1FormRecord, [id: string | number], [id: string | number]>({
    link: ApiUrl.M1FormRecords,
    onCreateUpdateItem: onCreateUpdatePatientItem,
    subscription: {
        subscriptionData: SubscriptionDataM1Form,
        setSummary: setPatientSummary
    }
})

export const postAppend = async (formId: string | number, text: string): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.M1FormRecords}/${formId}/append`, {
            id: formId,
            text
        }, { withCredentials: true, interceptError: true })
        const data: IPatchResponse = response.data
        if (data?.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}