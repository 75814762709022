const CLIENT_COMPLIANCE = '/client/:subjectName/compliance';
const CLIENT_MESSAGES = '/client/:subjectName/messages';
export const AppUrl = {
  NoteViewer: '/note-viewer',
  NotePreview: '/note-preview',
  FormPreview: '/form-preview',
  FormTemplatePreview: '/form-template-preview',
  AdditionalInfo: '/additional-info',
  AdministrativeNotes: '/administrative-notes',
  SETUP_PROFILE: '/setup-profile',
  COHORT_MANAGER: '/cohort-manager',
  COHORT_MANAGER_CREATE: '/cohort-manager/create',
  COHORT_MANAGER_EDIT: '/cohort-manager/edit',
  COUNSELING_NOTES_OF_CONCERN: '/concern',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  SESSION_LOGIN: '/session-login',
  FORGOT: '/forgot',
  CLINIC: '/clinic',
  SCHEDULE: '/schedule',
  PATIENTS: '/patients',
  REVIEWS: '/reviews',
  ORDERS: '/orders',
  BILLING: '/billing',
  COMPLIANCE: '/compliance',
  JOBS: '/jobs',
  CLIENT_COMPLIANCE,
  COMPLIANCE_OVERVIEW: '/overview',
  COMPLIANCE_ANALYSIS: '/analysis',
  COMPLIANCE_FACTS: '/facts',
  // --
  // -- Client messages
  CLIENT_MESSAGES,
  CLIENT_MESSAGES_ROUTE: '/messages',
  // --
  APPOINTMENT_HISTORY: '/appointment-history',
  SUBJECT: '/compliance/:cohortName/subject',
  COHORT_COMPLIANCE_SUBJECT: '/subject',
  SUBJECTS: '/subjects',
  COUNSELING: '/counseling',
  MEDICAL: '/medical',
  NOTES: '/notes',
  CURRENTNOTE: '/current-note',
  APPHISTORY: '/appointment-history',
  SCPM: '/scpm',
  REPORTS: '/reports',
  CATS: '/cats',
  SYSTEMSETUP: '/system-setup',
  ADMIN: '/admin',
  USER_PREFERENCES: '/user-preferences',
  WAITING_LIST_SETTINGS: '/waiting-list-settings',
  MESSAGES: '/messages',
  ACTIVITY_MESSAGES: '/activity/messages',
  PATIENTHEADERSETTINGS: '/patient-header-settings',
  COHORT: '/compliance/cohort-manager/cohort',
  RULEEDITOR: '/system-setup/rule-manager/rule-editor',
  RULEVIEWER: '/system-setup/rule-manager/rule-viewer',
  RULEMANAGER: '/rule-manager',
  NOTIFICATIONMANAGER: '/notification-manager',
  NOTIFICATIONVIEWER: '/system-setup/notification-manager/notification-viewer',
  NOTIFICATIONEDITOR: '/system-setup/notification-manager/notification-editor',
  NEWRULE: '/new-rule',
  EDITRULE: '/edit-rule',
  COHORTMANAGER: '/compliance/cohort-manager',
  NEWCOHORT: '/new-cohort',
  OVERVIEW: '/overview',
  MEMBER: '/member',
  FACTS: '/facts',
  COHORTCOMPLIANCEVIEWER: '/cohort-compliance-viewer',
  NEWMESSAGE: '/new-message',
  FORWARDMESSAGE: '/forward-message',
  REPLYMESSAGE: '/reply-message',
  FORM: '/form',
  NOTE: '/note',
  ADDNOTE: '/add-note',
  CLIENT: '/client',
  NEWNOTE: '/new-note',
  NOTE_TYPE: '/note-type',
  NEWFORM: '/form/new-form',
  MEDICAL_FORMS: '/forms',
  COUNSELINGFORM: '/forms',
  PATIENT_FORMS: '/forms',
  COUNSELINGNOTES: '/notes',
  PATIENT_TODO: '/todo',
  PATIENT_NOTES: '/notes',
  COUNSELING_NEW_NOTES: '/new-notes',
  COUNSELING_IN_PROGRESS_NOTES: '/in-progress-notes',
  PATIENTINFO: '/client-info',
  DEMOGRAPHICS: '/demographics',
  STUDENTINFO: '/student-info',
  OTHERCONTACTS: '/contacts',
  INSURANCE: '/insurance',
  CLAIM_PROFILE: '/claim-profile',
  ACTIVITY: '/activity',
  PAGE_ACTIVITY: '/page-activity',
  MYCLIENTS: '/my-clients',
  UNASSIGNEDCLIENTS: '/unassigned-clients',
  ACTIVITYMESSAGES: '/messages',
  NEWSECUREMESSAGE: '/new-secure-message',
  SECUREMESSAGE: '/secure-message',
  INTERNALMESSAGE: '/internal-message',
  NEWINTERNALMESSAGE: '/new-internal-message',
  COUNSELINGDASHBOARD: '/dashboard',
  COUNSELINGALERTS: '/alerts',
  COUNSELINGASSESSMENTS: '/assessments',
  COUNSELINGATTACHMENTS: '/attachments',
  COUNSELINGCONSENTS: '/consents',
  COUNSELINGCCAPS: '/ccaps',
  COUNSELINGMEDICATIONS: '/medications',
  COUNSELINGDIAGNOSISTREATMENTPLAN: '/diagnosis-treatment-plan',
  COUNSELING_DIAGNOSIS: '/diagnosis',
  COUNSELINGHOSPITALIZATIONS: '/hospitalizations',
  COUNSELINGREFERRALS: '/referrals',
  COUNSELINGNEWREFERRALS: '/counselingReferrals',
  COUNSELINGRISKS: '/risks',
  ALLERGIES: '/allergies',
  COUNSELING_DYNAMICS: '/39',
  DASHBOARDSETTINGS: '/counseling-dashboard-settings',
  DOCUMENTLIST: '/documentList',
  DOCUMENT: '/document',
  FORM_BUILDER: '/form-builder',
  BUILDING_BLOCK: '/building-block',
  BUILDING_FORM: '/building-form',
  TRAINEE: '/trainee',
  CONFLICT_RESOLUTION_MANAGER: '/conflict-resolution-manager',
  SUPERVISION_NOTES: '/supervision-notes',
  FORM_VIEWER: '/form-viewer',
  TRAINEE_FORM_VIEWER: '/trainee-form-viewer'
};
