import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const HeaderContainerAbsolute = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  overflow: auto;
  scrollbar-width: none;
  padding-right: 0.7rem;

  &::-webkit-scrollbar {
    width: 0.7rem;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
  }
`;

export const HideSpace = styled.div`
  width: 0.7rem;
  position: absolute;
  right: 0;
  top: 0;
`;

export const DroppableContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ListInnerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  overflow-y: overlay;
`;

export const Wrapper: any = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  opacity: ${({ isDropDisabled }: any) => (isDropDisabled ? 0.5 : 'inherit')};
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;

  .table-row-item-drag {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 3px;

    .render-row-item {
      display: flex;
      flex: 1;
      align-items: center;
    }

    &.isDragging {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  .drag-icon {
    margin: 0 0 0 10px;
    height: 14px;
    width: 14px;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
    background: ${globalStyleVariables.brandGray};

    &:hover {
      background: black;
    }
  }
`;

export const AbsoluteLoading = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
`;

interface IContainer {
  pushHightlightColor?: string;
  enableBorder?: boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: transparent;

  ${({ enableBorder }) =>
    enableBorder &&
    `
    border: 1px solid ${globalStyleVariables.borderColor};
    border-radius: 3px;
  `}
  .table-row-item {
    background-color: transparent;
    transition: background-color 0.5s ease;

    &.updated-by-push {
      background-color: ${({ pushHightlightColor }) =>
        pushHightlightColor || globalStyleVariables.newBrandingLightGreen};
      //pushHightlightColor || globalStyleVariables.superLightGreen};
    }
  }

  .row-hovering {
    .ant-col.data-column-item {
      background-color: white;
    }

    &:hover,
    &:focus-within,
    &.hovered {
      .ant-col.data-column-item {
        //background-color: #F7F7F7;
        background-color: ${globalStyleVariables.newBrandingTertiary1};
        transition: background-color 250ms linear;
      }
    }
  }

  .empty-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.disableMargin {
      .ant-empty-normal {
        margin: 0;
      }
    }
  }

  .on-click {
    transition: all 0.4s ease-out;
    background-color: rgba(0, 0, 0, 0);

    &:hover,
    &:active,
    &:focus {
      background-color: ${globalStyleVariables.newBrandingHoverMenuItemBG};
      cursor: pointer;
    }
  }

  .opened {
    background-color: rgba(14, 109, 159, 0.5);
  }

  .order-button {
    filter: grayscale(1);
    pointer-events: none;
    opacity: 0.6;

    &.active {
      filter: unset;
      pointer-events: unset;
      opacity: 1;
    }
  }

  .data-column-item {
    overflow: hidden;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 12px 5px;

    &.opened {
      color: white !important;
    }

    &.hightlighted {
      font-weight: bold;
    }
  }

  .color-container {
    display: flex;
    align-items: center;
    transition: color 0.25s ease-in-out;
  }

  .sort-icon-container {
    height: 0;
    width: 0;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out,
      width 0.25s ease-in-out, height 0.25s ease-in-out,
      margin 0.25s ease-in-out;
    opacity: 0;
    transform: scale(0);
    margin: 0;

    &.active {
      opacity: 1;
      transform: scale(1);
      width: 10px;
      height: 10px;
      margin: 0 0 0 5px;
    }

    .sortIcon {
      height: 10px;
      width: 10px;
      background: currentColor;
      mask-size: contain;
      mask-position: center center;
      mask-repeat: no-repeat no-repeat;
      transition: transform 0.25s ease-in-out;

      &.up {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
`;

export const PaginationContainer = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${globalStyleVariables.borderColor};
`;
