import React, { FC, useCallback, useMemo, useRef } from 'react';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot
} from 'react-beautiful-dnd';
import { Wrapper, DroppableContainer } from './styled';
import { IProps as IMainProps } from './types';
import TableRow, { DraggableItem } from './TableRow';
import { tableListOrders } from './utils';
import _ from 'lodash';
import memoize from 'fast-memoize';

interface IProps extends IMainProps {
  pageNumber: number;
  pageLength: number;
  containerRef?: React.RefObject<HTMLDivElement>;
  headerWidth: number;
}

function onDragStart() {
  if (window.navigator.vibrate) {
    window.navigator.vibrate(100);
  }
}

const ItemContainer = (
  props: Omit<IProps, 'data' | 'dataSource'> & {
    value: any;
    index: number;
    lastItem: boolean;
  }
) => {
  const {
    value,
    onRowOpen,
    openedRows,
    index,
    hightlightFunction,
    selectedRowsFunction,
    dragAndDropProps,
    rowComponentContainer,
    lastItem,
    containerRef
  } = props;
  let opened = false;
  if (value.underRowComponent) {
    if (onRowOpen) {
      opened = onRowOpen(value);
    } else {
      if (openedRows) {
        opened = openedRows?.some((a) => a === index);
      }
    }
  }
  const hightlighted = hightlightFunction && hightlightFunction(value);
  const selected = selectedRowsFunction && selectedRowsFunction(value);
  const RowItem = dragAndDropProps ? DraggableItem : TableRow;
  if (rowComponentContainer) {
    return rowComponentContainer({
      children: (
        <RowItem
          {...props}
          key={`row-${value.id || index}`}
          index={index}
          value={value}
          hightlighted={hightlighted}
          opened={opened}
          selected={selected}
          lastItem={lastItem}
          containerRef={containerRef}
        />
      ),
      value,
      index
    });
  }
  return (
    <RowItem
      {...props}
      index={index}
      value={value}
      hightlighted={hightlighted}
      opened={opened}
      selected={selected}
      lastItem={lastItem}
      containerRef={containerRef}
    />
  );
};

const typedMemo: <T extends React.ComponentType<any>>(
  c: T,
  areEqual?: (
    prev: React.ComponentProps<T>,
    next: React.ComponentProps<T>
  ) => boolean
) => T = React.memo;

export const Item = typedMemo(ItemContainer, (props, nextProps) => {
  // For performance, we can check memoize property changed or not
  // We can't check all value because inside value we can use Components which we can't compare
  // If we have same object as we had previous we don't need to rerender this row
  if (props.value.memoizeBy) {
    const memoizedChecker =
      _.isEqual(props.value.memoizeBy, nextProps.value.memoizeBy) &&
      props.openedRows === nextProps.openedRows &&
      props.selectedRowsFunction === nextProps.selectedRowsFunction &&
      props.hightlightFunction === nextProps.hightlightFunction;
    return memoizedChecker;
  }
  return false;
});

const MemoItem = React.memo(ItemContainer);

const Component: FC<IProps> = (props: IProps) => {
  const {
    sort,
    data,
    defaultSortOptions,
    enablePagination,
    pageNumber,
    pageLength,
    onRowOpen,
    openedRows,
    hightlightFunction,
    dragAndDropProps,
    selectedRowsFunction,
    rowComponentContainer,
    dataSource,
    renderItemData,
    onClickRow,
    disableDefaultSort,
    columns,
    innerPagination,
    headerWidth
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const listRenderItemData = useCallback(
    renderItemData ? memoize(renderItemData) : () => ({}),
    [renderItemData]
  );

  const list = useMemo(() => {
    const arr = data
      ? data
      : dataSource
      ? dataSource.map(listRenderItemData)
      : [];
    let listData = disableDefaultSort
      ? arr
      : sort
      ? tableListOrders(arr, sort?.sortBy, sort?.sortType, defaultSortOptions)
      : arr;
    if (enablePagination || innerPagination) {
      listData = listData.slice(
        (pageNumber - 1) * pageLength,
        (pageNumber - 1) * pageLength + pageLength
      );
    }
    return listData.map((value: any, index: number) => {
      const { dataSource, data, ...otherProps } = props;
      return (
        <MemoItem
          key={`row-${value.id || index}`}
          {...otherProps}
          value={value}
          index={index}
          lastItem={index < listData!.length - 1}
          containerRef={containerRef}
        />
      );
    });
  }, [
    sort,
    data,
    pageNumber,
    pageLength,
    onRowOpen,
    openedRows,
    hightlightFunction,
    dragAndDropProps,
    selectedRowsFunction,
    rowComponentContainer,
    dataSource,
    onClickRow,
    renderItemData,
    columns,
    innerPagination
  ]);
  if (dragAndDropProps) {
    function onDragEnd(result: any) {
      // combining item
      if (result.combine) {
        dragAndDropProps?.onChangeOrder(
          result.source.index,
          result.destination.index
        );
        return;
      }
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      if (result.destination.index === result.source.index) {
        return;
      }
      dragAndDropProps?.onChangeOrder(
        result.source.index,
        result.destination.index
      );
    }

    return (
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <DroppableContainer>
          <Droppable droppableId={'list-dnd'} type="test">
            {(
              dropProvided: DroppableProvided,
              dropSnapshot: DroppableStateSnapshot
            ) => (
              <Wrapper
                isDraggingOver={dropSnapshot.isDraggingOver}
                isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
                {...dropProvided.droppableProps}
              >
                <div
                  style={{
                    width: headerWidth
                      ? `calc(${headerWidth}px - 0.7rem)`
                      : '100%'
                  }}
                  ref={dropProvided.innerRef}
                >
                  {list}
                  {dropProvided.placeholder}
                </div>
              </Wrapper>
            )}
          </Droppable>
        </DroppableContainer>
      </DragDropContext>
    );
  }
  return (
    <div
      tabIndex={0}
      ref={containerRef}
      //calc for body scroll
      style={{
        width: headerWidth ? `calc(${headerWidth}px - 0.7rem)` : '100%'
      }}
    >
      {list}
    </div>
  );
};

export default React.memo(Component);
