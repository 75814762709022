import store from 'src/store';

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const withoutSpaces = (text: string) => {
  return text.replace(/\ /g, '');
};

export const isNumber = (str: string) => {
  var pattern = /^\d+\.?\d*$/;
  return pattern.test(str);
}

export function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}
export function validateEmail(email?: string) {
  if (!email) {
    return false;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePhoneWithExt(phoneNumber?: string) {
  if (!phoneNumber) {
    return false;
  }
  const withExt = /^[0-9]{15}$/;
  const withoutExt = /^[0-9]{10}$/;
  return withExt.test(phoneNumber) || withoutExt.test(phoneNumber);
}

export const toISOLocalString = (date: Date) => {
  return date.toLocaleString('sv-SE').replace(' ', 'T').split('.')[0];
};

export const patientDisplayName = (
  patient:
    | {
      firstName: string;
      lastName: string;
      nickName?: string;
      preferredName?: string;
      id?: number;
    }
    | undefined
    | null,
  format?: 'withId'
): string => {
  if (!patient) return '';
  const defaultFormat = patient
    ? `${patient.nickName || patient.preferredName || patient.firstName || ''
    } ${patient.lastName || ''}`
    : '';
  if (format === 'withId') {
    return `${defaultFormat} [${patient.id}]`;
  }
  return defaultFormat;
};
export const patientDisplayNameById = (patientId: string | number): string => {
  const patient = store.getState().client.patientHeader[patientId + ''];
  if (!patient) return '';
  return patient
    ? `${patient.nickName || patient.preferredName || patient.firstName || ''
    } ${patient.lastName || ''}`
    : '';
};

export const clinicDisplayName = (clinic?: { name: string; code: string }) => {
  if (!clinic) return '';
  return `${clinic.name} (${clinic.code})`;
};
