export const ApiUrl = {
  PortalClinicAddress: '/PortalClinicAddress',
  ChartNote: '/ChartNote',
  ReqOrders: '/ReqOrders',
  PortalStatus: '/PortalStatus',
  PortalTemplate: '/PortalTemplate',
  PortalIntakeTemplate: '/PortalIntakeTemplate',
  PortalDownloadForm: '/PortalDownloadForm',
  EducationMaterial: '/EducationMaterial',
  PortalDocumentUpload: '/PortalDocumentUpload',
  //Share management
  ShareFormTemplateAdmin: '/ShareFormTemplateAdmin',
  // ---
  PatientPortalOption: '/patientPortal/PatientPortalOption',
  LetterTemplate: '/LetterTemplate',
  ProviderOutOfOffice: '/ProviderOutOfOffice',
  Blaster: '/PatientBlaster',
  PatientWaitingListComment: '/PatientWaitingListComment',
  PatientListComment: '/PatientListComment',
  PatientLists: '/PatientLists',
  ShareFormTemplate: '/ShareFormTemplate',
  ShareFormBuildingBlockTemplate: '/ShareFormBuildingBlockTemplate',
  DiagnosisCode: '/Diagnosis',
  PUBLIC_FORMS: 'form/public',
  PatientAdditionalInfo: '/PatientAdditionalInfo',
  PatientCustomFields: '/PatientCustomFields',
  AppointmentComments: 'AppointmentComments',
  NOTES_V4: '/notes/v4',
  TypeMessage: '/TypeMessage',
  TXN_CODES_V2: '/TxnCodesV2',
  txnCodeHomework: '/TxnCodeHomework',
  txnCodePointOfCare: '/TxnCodePointOfCare',
  TypeCheckInStatus: '/TypeCheckInStatus',
  typeAllergyInactive: '/typeAllergyInactive',
  GroupTxnClinicLink: '/GroupTxnClinicLink',
  GroupDiagnosisClinicLink: '/GroupDiagnosisClinicLink',
  PatientAllergies: '/PatientAllergies',
  PatientInformation: '/PatientInformation',
  PatientChartMenu: '/PatientChartMenu',
  SummaryReviewLog: '/SummaryReviewLog',
  PortalProviderMessaging: '/PortalProviderMessaging',
  Inventory: '/Inventory',
  ReqOrder: '/ReqOrders',
  ImmuneLetterDetails: '/ImmuneLetterDetails',
  ImmunizationResults: '/ImmunizationResults',
  CPT_CODES: '/cptCodes',
  Hippa: '/Hipaa',
  SelfCheckins: '/SelfCheckins',
  TypeStudentStatus: '/GenericTypes/TypeStudentStatus',
  typeImmuneStatus: '/GenericTypes/TypeImmuneStatus',
  CompliancePatients: '/reports/compliancePatients',
  CompliancePatientsIds: '/reports/compliancePatientIds',
  cohortPatientStatistics: '/reports/cohortPatientStatistics',
  compliancePatientHeader: '/reports/compliancePatientsHeader',
  ProviderActuals: '/Actuals',
  GetRuleDefinition: '/Rule/GetRuleDefinition',
  GetRuleInfo: '/Rule/GetRuleInfo',
  MESSAGES_COUNT: '/messagesv2/counts',
  M1Notes: 'M1Notes',
  ticketDiagnoses: '/ticketDiagnoses',
  ticketDiagnosesV2: '/ticketDiagnoses/v2',
  SSO: '/auth/v2/sso',
  Tenants: 'Tenants',
  M1FormRecords: 'M1FormRecords',
  ProviderAgreements: '/ProviderAgreements',
  ProviderAssessments: '/ProviderAssessments',
  ProviderEvaluations: '/ProviderEvaluations',
  ProviderConsents: '/ProviderConsents',
  ProviderContracts: '/ProviderContracts',
  ProviderDocuments: '/ProviderDocuments',
  TraineeContacts: '/TraineeContacts',
  TraineeGoals: '/traineeGoals',
  Trainees: '/trainees',
  TicketDetail: '/TicketDetails/v2',
  SupervisorRoles: '/SupervisorRoles',
  PROVIDER_ASSIGNED_SUPERVISOR: '/ProviderSupervisorAssignments',
  GROUP_TXN: '/groupTxn',
  SETTINGS: '/settings',
  CHART_NOTES: '/chartNotes',
  MAJORS: '/majors',
  GET_ALLERGY: '/PatientSummary/GetAllergy',
  OUTREACH: '/outreach',
  OUTREACH_REGISTRANTS: '/outreachRegistrants',
  RCOPIA: '/Rcopia',
  RX_DISCONTINUE_REASON: '/rxdiscontinuereason',
  REGPRESCRIPTION: '/reqprescription',
  AUTH_V2: '/auth/v2',
  APPOINTMENT_REASON_FORMS: '/AppointmentReasonForms',
  // Tags
  TAGS_DEFINITIONS: '/tags/definitions',
  TAGS_VALUES: '/tags/values',
  TAGS_LINKS: '/tags/links',
  //
  SOAP_NOTE_APPEND: '/soapNoteAppend',
  SPELL_REPLACEMENT: '/SpellReplacement',
  AUTOMATED_TASKS: '/AppointmentTasks',
  TABLEAU: '/tableau',
  BREAK_GLASS_AUDIT: '/BreakGlassAudit',
  USER_PATIENT_ACCESS: '/UserPatientAccess',
  PATIENT_CONTACTS: '/patientContacts',
  OTHER_PARTY: '/otherParty',
  FACILITY: '/Facilities',
  PATIENTS: '/patients',
  ClinicProviderHierarchies: '/clinicProviderHierarchies',
  UserPatientCookieCrumbs: '/UserPatientCookieCrumbs',
  TypeResidency: '/GenericTypes/TypeResidency',
  TypeClass: '/GenericTypes/TypeClass',
  TypeStanding: '/GenericTypes/TypeStanding',
  TypeSchool: '/GenericTypes/TypeSchool',
  GroupInsurance: '/GenericTypes/GroupInsurance',
  TypeEligibility: '/GenericTypes/TypeEligibility',
  TypeCampusStatus: '/GenericTypes/TypeCampusStatus',
  TypeFee: '/GenericTypes/TypeFee',
  TypeGender: '/GenericTypes/TypeGender',
  GroupFacility: '/GenericTypes/GroupFacility',
  MedicatDemo: '/GenericTypes/MedicatDemo',
  CampusHousing: '/GenericTypes/CampusHousing',

  TypeProviderClaimLogic: '/GenericTypes/TypeProviderClaimLogic ',
  //AppointmentReasons
  GroupAppointmentReason: '/GenericTypes/GroupAppointmentReason',
  CCMHAppointmentCategory: '/GenericTypes/CCMHAppointmentCategory',
  // ---Insurance generic types
  TypeInsurance: '/GenericTypes/TypeInsurance',
  TypeInsuranceWaiverTerm: '/GenericTypes/TypeInsuranceWaiverTerm',
  TypeInsuranceWaiverStatus: '/GenericTypes/TypeInsuranceWaiverStatus',
  TypeRelationship: '/GenericTypes/TypeRelationship',
  // ---
  PATIENT_INSURANCES: '/patientInsurances',
  INSURANCE_COMPANIES: '/insuranceCompanies',
  GET_PATIENT_ALERT_TYPES: '/PatientAlertTypes',
  GET_PATIENT_ALERTS: '/PatientAlerts',
  GET_PATIENTS: '/activityReports/patients',
  GET_ASSESSMENTS: '/patientAssessments',
  GET_PATIENT_ASSIGNMENTS: '/patientProviderAssignments',
  GET_NOTES_OF_CONCERN: '/NotesOfConcern',
  GET_REFFERALS: '/referrals',
  GET_TASKS: '/tasks',
  GET_TODOS: '/todos',
  GET_MY_CLIENTS: '/activityReports/myPatient',
  GET_MY_CLIENTS_PROVIDER_WATCHLIST: '/providerWatchlists',
  GET_CONSENTS: '/patientConsents',
  GET_RULES: 'Rule/GetRules',
  GET_RULE_COMPONENTS: 'Rule/GetRuleComponents',
  GET_RULE_METADATA: 'Rule/GetRuleMetaDataByName',
  GET_IMMUNIZATIONS: 'Fact/GetImmunizations',
  GET_IMMUNIZATIONS_FOR_FACTS: 'Fact/SearchActiveImmunizations', //added for facts
  GET_PROCEDURES: 'Fact/GetProcedures',
  GET_DOCUMENT_CODES: 'Fact/GetDocumentCodes',
  GET_ALL_FACTS: 'Fact/GetAllFacts',
  GET_ALL_COHORTS: 'Cohort/GetAllCohorts',
  GET_SUBJECTS: 'Subject/GetAllSubjectsComplianceSummary',
  GET_APPT_SUBSCRIPTIONS: 'user/ApptSubscriptions',
  FACILITIES: 'Facilities',
  // CORPORATESSCREENING
  GET_CORPORATESSCREENING_ACCESSTOKEN: 'auth/getcorporatescreeningaccesstoken',
  GET_CORPORATESSCREENING_AUTHORIZATION_REDIRECT:
    'auth/getcorporatescreeningauthorizationredirect',
  GET_COMPLIANCE_REVIEW_LOG: '/subject/GetComplianceReviewLog',
  GET_SUBJECT_OVERVIEW: '/Subject/GetSubjectOverView',
  GET_MEMBERSHIP_FULL_PREVIEW: 'Cohort/GetMemberShipFullPreviewEX',
  GET_MEMBERSHIP_INCREMENTAL_PREVIEW: 'Cohort/GetMemberShipIncrementalPreview',
  GET_DOCUMENT_DETAILS: 'Fact/GetDocumentDetails',
  GET_MESSAGES: 'Message/GetAllIncomingSecureMessageForProviders',
  GET_COHORT_OVERVIEW: 'Cohort/GetCohortOverView',
  GET_COHORT_OVERVIEW_EX: 'Cohort/GetCohortOverViewEx',
  GET_COHORT_MEMBERS: 'Cohort/GetAllMembers',
  GET_COHORT_MEMBERS_COMPLIANCES: 'Cohort/GetAllCompliancesOfMember',
  GET_COHORT_COMPLIANCES: 'Cohort/GetAllComplianceCriteriaEx',
  GET_COHORT_ID: 'Cohort/GetDashboardCohort',
  GET_COHORT_REVIEW_SUBJECTS: 'Cohort/GetAwaitingReviewSubjects',
  GET_SUBJECTS_BASIC_INFO: 'Subject/GetSubjectBasicInfo?subjectId=',
  GET_EXECUTION_RESULT: '/Cohort/GetExecutionResult?subId=',
  GET_COHORT_REUIREMENT_STATISTICS: '/reports/cohortRequirementStatistics',
  TICKER_DETAILS: '/TicketDetails',

  TYPE_EXPIRED_REASONS: '/TypeExpireReasons',
  POST_HOSPITALIZATIONS: '/hospitalizations/post',
  PATCH_HOSPITALIZATIONS: '/hospitalizations/patch',
  GET_HOSPITALIZATIONS: '/hospitalizations/get',
  GET_HOSPITALIZATIONS_TYPES: '/hospitalizationTypes/get',
  GET_HOSPITALIZATIONS_STATUSES: '/hospitalizationStatuses/get',

  // -- Messages
  GET_MESSAGE_STATUSES: '/MessageStatuses/',
  GET_MESSAGES_FOLDERS: '/messagesv2/folders',
  GET_PATIENT_MESSAGES_FOLDERS: `/messagesv2/patientFolders/`,
  GET_MESSAGES_LIST: '/messagesV2/',
  UNASSIGNED_CLIENTS: '/activityReports/unassignedPatient',
  POST_MESSAGE: '/messagesv2/',
  PATCH_MESSAGE: '/messagesv2/',
  PUT_FOLLOW_UP: '/messagesv2/',
  GET_USERS_LISTS: '/users',
  GET_USER_GROUPS: '/userGroups',
  GET_GROUP_USERS: '/groupUsers',
  SECURITY: '/Security',
  PERMISSIONS_FUNCTIONS: '/Permissions/functions',
  PERMISSIONS_MODULES: '/Permissions/modules',
  PERMISSIONS_MODULE_FUNCTIONS: '/Permissions/moduleFunctions',
  // --
  RULE_REFRESH: '/Rule/Refresh',
  ADD_COMPLIANCE: 'Cohort/AddCompliance',
  GET_COMPLIANCE_PREVIEW: '/Cohort/GetCompianceFullPreviewEX',
  GET_SUBJECT_MESSAGES: 'Message/GetSubjectMessages',
  GET_SUBJECT_MESSAGE: 'Message/GetSecureMessage',
  UPDATE_READ_STATUS: 'Message/UpdateMessageStatus',
  GET_DOCUMENT_META_DATA: 'Fact/GetDocumentMetadata',
  GET_FACT_META_DATA: 'Fact/GetFactMetadata',
  GET_FACT_DOCUMENT_META_DATA: 'Fact/GetFactDocumentMetadata',
  GET_FACT_USER_PREFERENCES: 'User/GetUserPreferences',
  SAVE_FACT_USER_PREFERENCES: 'User/SaveUserPreferences',
  GET_PATIENT_COMPLIANCE_DETAIL: 'Fact/GetPatientComplianceDetail',
  GET_DOCUMENT: 'Fact/GetDocument',
  GET_FACT_HISTORY: 'Fact/GetPatientComplianceDetailLog',
  GET_DOCUMENT_HISTORY: 'Fact/GetPatientComplianceDocumentLog',
  UPDATE_DOCUMENT_STATUS: 'Fact/UpdateDocumentStatus',
  UPDATE_DOCUMENT_DESCRIPTION: 'Fact/UpsertDocumentComments',
  UPDATE_COHORT: 'Cohort/UpdateCohort?cohortId=',
  CREATE_BROADCAST_MESSAGE: 'Message/CreateBroadCastMessage',
  CREATE_BROADCAST_MESSAGE_EX: 'Message/CreateBroadCastMessageEx',
  REMOVECOMPLIANCECRITERIA: 'Cohort/RemoveComplianceCriteria',
  GET_SUBJECT_COMPLIANCES: 'Subject/GetAllCompliancesOfSubjectEx',

  UPDATE_COMPLIANCE_REVIEWSTATUS: 'Subject/UpdateComplianceReviewStatus',

  REMOVE_FACT: 'Fact/RemoveFact',
  REMOVE_DOCUMENT: 'Fact/RemoveDocument',
  REMOVE_FACT_DOCUMENT: 'Fact/RemoveFactDocument',
  UPDATE_FACT_STATUS: 'Fact/UpdateFactStatus',
  ADD_FACT: 'Fact/AddFact',
  GET_MESSAGE_TEMPLATE: 'Message/GetMessageTemplates',
  GET_MESSAGE_TEMPLATE_CONTENT: 'Message/GetTemplateContent?templateId=',
  USER_LOGIN: 'Auth/Login',
  INTERNAL_LOGIN: 'Auth/InternalLogin',
  USER_LOGOFF: 'Auth/LogOff',
  GET_PERMISSION: 'Auth/InitClinicPermissions',
  GET_RECIPIENTS: 'Subject/PerformSubjectSearch',
  CREATE_MESSAGE: 'Message/CreateMessage',
  CHECK_LOGIN_STATUS: 'Auth/CheckLoginStatus',
  SAVE_USER_PREFERENCES: 'User/SaveUserPreferences',
  GET_USER_PREFERENCES: 'User/GetUserPreferences',
  GET_CURRENT_USER_PREFERENCE: 'User/GetCurrentUserPreferences',
  GET_CURRENT_USER_PERMISSION: 'User/GetCurrentUserPermissions',
  GET_DEMOGRAPHICS_COMPONENT: 'Fact/GetDemoGraphicsComponent',
  GET_ALL_NOTIFICATION_TEMPLATES: 'Message/GetMessageTemplates',
  GET_NOTIFICATION_TEMPLATE: 'Message/GetTemplate',
  CREATE_NOTIFICATION_TEMPLATE: 'Message/CreateTemplate',
  DELETE_NOTIFICATION_TEMPLATE: 'Message/DeleteTemplate',
  UPDATE_NOTIFICATION: 'Message/UpdateTemplate',
  GET_ALL_LETTER_CODES_AND_TYPES: 'Message/GetAllLetterCodesAndType',
  // -- Schedule favorites
  GET_SCHEDULE_FAVORITE: 'scheduleFavorite/Get',
  POST_SCHEDULE_FAVORITE: 'scheduleFavorite/Post',
  PATCH_SCHEDULE_FAVORITE: 'scheduleFavorite/Patch',
  // --
  GET_CLINICS: 'Schedule/GetClinics',
  GET_PROVIDERS: 'Schedule/GetProvidersByClinic',
  GET_PROVIDERS_FROM_CLINICS: 'Schedule/GetClinicProviders',
  UPLOAD_FACT_DOCUMENT: 'Fact/SaveDocument?subjectId=',
  GENERATE_REPORT_PDF: 'Report/GenerateReportPdf',
  UPDATE_PATIENT_COMMENT: 'Subject/UpdatePatientComment',
  NEW_TEMPLATE_FORM_RECORD: '/forms/records',
  GET_EXECUTION_RESULT_BY_PATIENT: 'Subject/GetExecutionResultsByPatient',
  GET_MESSAGE_PROVIDER_FOR_CLINIC: 'Message/GetMessageProviderForClinic',
  //Deprecated
  GET_TEMPLATE_FORM_RECORD: 'TemplateFormRecord/GetTemplateFormRecord',
  GET_TEMPLATE_FORM_NEW: '/forms/records',
  GET_TEMPLATE_FORM_RECORD_DETAIL_NEW: '/forms/records/details',
  GET_FORM_TEMPLATE: '/forms/templates',
  TYPE_RISKS: '/typeRisks',
  CATEGORIES: '/Categories',
  UKVP: 'Ukvp',
  DIAGNOSIS_TREATMENT_PLAN: '/diagnoses/diagnosisTreatmentPlan',
  CLEAR_JOB_QUEUE: '/Job/ClearJobQueue',
  GET_JOBS: '/Job/GetJobsStatus',
  TICKET_DIAGNOSES: '/ticketDiagnoses',
  SOAP_DIAGNOSES: '/soapDiagnoses',
  TYPE_PROVIDERS: '/typeProviders',
  GroupProvider: 'GenericTypes/GroupProvider',
  TypeSpecialty: 'GenericTypes/TypeSpecialty',
  TypeServiceProvider: 'GenericTypes/TypeServiceProvider',
  GET_TEMPLATE_FORM: 'TemplateForm/GetTemplateForms',
  CLINIC_PROVIDERS: '/ClinicProviders',
  GET_ALL_PROVIDERS: '/Providers',
  GET_INSURANCE_COMPANIES: '/insuranceCompanies',
  GET_PROVIDERS_TYPES: '/Providers/Types',
  GET_PATIENT_LIST: 'Subject/GetPatientLists',
  GET_PATIENT_INFO: 'Subject/GetPatientInfo',
  SAVE_PATIENT_INFO: 'Subject/SavePatientInfo',
  GET_PATIENT_IMAGE: 'Subject/GetPatientImage',
  SAVE_PATIENT_IMAGE: 'Subject/SavePatientImage?subjectId=',
  GET_PATIENT_HEADER: 'Subject/GetPatientHeader',
  GET_SUMMARY_DETAIL_RX: 'PatientSummary/GetSummaryRx',
  GET_AVAILABLE_CATEGORIES: 'PatientSummary/GetAvaliableCategories',
  NOTES: 'Notes',
  DIAGNOSIS_WK: 'Diagnosis/wk/',
  GET_NOTESV2: 'notes',
  //V1 Deprecated
  GET_NOTES: 'Notes/Get',
  NOTE_TYPES: 'notes/types',
  NOTE_TYPES_LINK: 'NoteTypes/',
  //V1
  GET_SET_INFO_FOR_PLAN: 'TxnSets/Get',
  //V1
  GET_DIAGNOSIS_GROUPS: 'DiagnosisGroups/Get',
  DIAGNOSIS: 'diagnoses',
  GET_DIAGNOSIS: 'Diagnosis/Get',
  //V1 Deprecated
  TXNCODES: 'TxnCodes',
  SEND_NOTE: 'Notes/Send',
  FILES_UPLOAD: 'Files/Upload',
  FILES_MULTIPART_UPLOAD: 'files',
  FILES_DOWNLOAD: 'Files/Download',
  CREATE_ATTACHMENT: 'Attachments/Post',
  GET_ATTACHMENTS: 'Attachments/Get',
  UPDATE_ATTACHMENTS: 'Attachments/Put',
  GET_PATIENT_ATTACHMENTS: 'Files/Patient',
  UPDATE_PATIENT_ATTACHMENT: 'Files',
  GET_NOTE_PREVIEW: 'Notes/Preview',
  GET_APPOINTMENT_HX: 'Schedule/GetAppointmentHx',
  NOTE_THREADS: 'ConvoThreads',
  NOTE_COMMENTS: 'ConvoComments',
  GET_PATIENT_RISKS: 'patientRisks',
  GET_PATIENT_CCAPS: 'ccaps',
  REQ_STATUS: 'Req/Status',
  REQ_TYPE: 'Req/Priority',
  REQ_ORDER: 'Req/Order',
  REFERRAL_RESULTS_LOG: 'ReferralResultLogs',
  REFERRAL_RESULTS: 'ReferralResults',
  ROOM_TYPES: 'GenericTypes/TypeRoom',
  ACUITY_TYPES: 'GenericTypes/TypeAcuity',
  EMR_TYPES: 'GenericTypes/TypeEmrseenBy',
  TRUSTED_TICKET: 'tableau/trustedTicket',
  APPOINTMENTS: 'appointments',
  APPOINTMENT_REASONS: 'appointmentReasons',
  CLINICS: 'clinics',
  TYPE_CLINIC: 'typeClinic',
  GET_SCHEDULE_EVENT: 'Schedule/GetScheduleEvent',
  GetArrivedCount: 'Schedule/GetArrivedCount',
  GetArrivedAppointments: 'Schedule/GetArrivedAppointments',
  DischargeAllAppointments: 'Schedule/DischargeAllAppointments',

  UserAssociatedProvider: 'userAssociatedProviders',
  M1FormsBuildingBlocks: 'M1Forms/buildingBlocks',
  M1FormsTemplates: 'M1Forms/templates',

  CONFLICTS_RESOLUTION: 'Schedule/GetScheduleCalendarConflicts',

  typeMedicareSecondaryPayor: 'GenericTypes/typeMedicareSecondaryPayor',
  typeDisability: 'GenericTypes/typeDisability',
  typeCHAPReferral: 'GenericTypes/typeCHAPReferral',
  typeOver90DayReasons: 'GenericTypes/typeOver90DayReasons',
  typeClaim: 'GenericTypes/typeClaim',
  typePatientStatus: 'GenericTypes/typePatientStatus',
  typeAbortionSterilization: 'GenericTypes/typeAbortionSterilization',
  typeEPSDTReferralCode: 'GenericTypes/typeEPSDTReferralCode',
  typeServiceAuth: 'GenericTypes/typeServiceAuth',

  PROVIDER_EVENT: 'Events',
  PROVIDER_EVENT_MEMBERS: 'EventMembers',
  PROVIDER_EVENT_HISTORY: 'Events/History',
  transactionSet: 'transactionSet',
  STATUS_SETUP: 'TypeReqStatus',
  RESULT_CODES: 'ReqResultStatus'
};
