import moment from 'moment';

import {
  SET_ACUITY_TYPES,
  SET_APPOINTMENTS,
  SET_DATEROWS,
  SET_EMR_TYPES,
  SET_FILTERS,
  SET_LOADING,
  SET_PAGINATION_DATA,
  SET_ROOM_TYPES
} from './ActivityAppointmentActions';
import { ColumnGlobalTitle } from 'src/Framework/Controls/Table/types';
import { TypeListEnum } from 'src/Activities/Schedule/WaitingListSettings/store/types';
import { ListTypeNames } from 'src/Activities/Schedule/WaitingListSettings/store/utils';

export enum AppointmentStatus {
  all = '(All Appointments)',
  yetToArrive = 'Yet to Arrive',
  arrived = 'Arrived',
  ready = 'Ready',
  admitted = 'Admitted',
  discharged = 'Discharged'
}

export const appointmentViews = {
  appointments: 'appointments',
  triage: TypeListEnum.triage,
  roster: TypeListEnum.roster
};

export type TAppointmentViews = TypeListEnum | 'appointments';

export interface IColumnSet {
  key: string;
  label: string;
  order: number;
  show: boolean;
}

export const defaultRosterColumns: IColumnSet[] = [
  // {
  //   label: 'Name',
  //   key: 'name',
  //   order: 1,
  //   show: true
  // },
  // {
  //   label: 'Code',
  //   key: 'code',
  //   order: 2,
  //   show: true
  // },
  // {
  //   label: 'Attachment',
  //   key: 'attachment',
  //   order: 3,
  //   show: true
  // },
  // {
  //   label: 'Status',
  //   key: 'status',
  //   order: 4,
  //   show: true
  // },
  // {
  //   label: 'Results',
  //   key: 'results',
  //   order: 5,
  //   show: true
  // },
  // {
  //   label: 'Priority',
  //   key: 'priority',
  //   order: 6,
  //   show: true
  // },
  // {
  //   label: 'Comment',
  //   key: 'comment',
  //   order: 7,
  //   show: true
  // },
  // {
  //   label: 'Probability',
  //   key: 'probability',
  //   order: 8,
  //   show: true
  // },
  // {
  //   label: 'Units',
  //   key: 'units',
  //   order: 9,
  //   show: true
  // },
  // {
  //   label: 'Unit Of Measure',
  //   key: 'unitOfMeasure',
  //   order: 10,
  //   show: true
  // },
  // {
  //   label: 'Min Range',
  //   key: 'minRange',
  //   order: 11,
  //   show: true
  // },
  // {
  //   label: 'Max Range',
  //   key: 'maxRange',
  //   order: 12,
  //   show: true
  // },
  // {
  //   label: 'Range Value',
  //   key: 'rangeValue',
  //   order: 13,
  //   show: true
  // },
  // {
  //   label: 'Result Value',
  //   key: 'resultValue',
  //   order: 14,
  //   show: true
  // },
  {
    label: 'Provider',
    key: 'provider',
    order: 15,
    show: true
  },
  {
    label: 'Client',
    key: 'client',
    order: 16,
    show: true
  },
  {
    label: 'ApptType',
    key: 'apptType',
    order: 17,
    show: true
  },
  {
    label: 'Tags',
    key: 'tags',
    order: 18,
    show: true
  },
  {
    label: 'Comments',
    key: 'comments',
    order: 19,
    show: true
  },
  {
    label: 'Time in a Roster',
    key: 'timeInRoster',
    order: 20,
    show: true
  },
  {
    label: 'Creation Date',
    key: 'creationDate',
    order: 21,
    show: true
  },
  {
    label: 'Chart',
    key: 'chart',
    order: 22,
    show: true
  },
  {
    label: 'Modify',
    key: 'edit',
    order: 23,
    show: true
  },
  {
    label: 'Eligibility',
    key: 'eligibility',
    order: 24,
    show: true
  },
  {
    label: ColumnGlobalTitle.accountNumber,
    key: 'patientId',
    order: 25,
    show: true
  }
];
export const defaultColumns: IColumnSet[] = [
  {
    label: 'Instruction',
    key: 'instructions',
    order: 12,
    show: true
  },
  {
    label: 'Appt Time',
    key: 'apptTime',
    order: 1,
    show: true
  },
  {
    label: 'End Time',
    key: 'apptEndTime',
    order: 2,
    show: true
  },
  {
    label: 'Client',
    key: 'client',
    order: 3,
    show: true
  },
  {
    label: 'Provider (Only for Appointment)',
    key: 'provider',
    order: 4,
    show: true
  },
  {
    label: 'Appt type',
    key: 'apptType',
    order: 5,
    show: true
  },
  {
    label: 'Appt Note',
    key: 'note',
    order: 6,
    show: true
  },
  {
    label: 'Late',
    key: 'late',
    order: 7,
    show: true
  },
  {
    label: 'Arrived',
    key: 'arrived',
    order: 8,
    show: true
  },
  {
    label: 'Ready',
    key: 'ready',
    order: 9,
    show: true
  },
  {
    label: 'Admitted',
    key: 'admitted',
    order: 10,
    show: true
  },
  {
    label: 'Discharged',
    key: 'discharged',
    order: 11,
    show: true
  },
  {
    label: 'Seen by provider',
    key: 'seenByProvider',
    order: 13,
    show: false
  },
  {
    label: 'Room',
    key: 'room',
    order: 14,
    show: false
  },
  // {
  //   label: 'Nurse',
  //   key: 'nurse',
  //   order: 15,
  //   show: false
  // },
  {
    label: 'Acuity',
    key: 'acuity',
    order: 16,
    show: false
  },
  {
    label: 'Seen By',
    key: 'seenBy',
    order: 17,
    show: false
  },
  // {
  //   label: 'Managing provider',
  //   key: 'managingProvider',
  //   order: 18,
  //   show: false
  // },
  {
    label: 'Present',
    key: 'present',
    order: 19,
    show: false
  },
  {
    label: 'Duration of Stay',
    key: 'duration',
    order: 20,
    show: false
  },
  {
    label: 'Waiting',
    key: 'waiting',
    order: 21,
    show: false
  },
  {
    label: 'Scheduled',
    key: 'scheduled',
    order: 22,
    show: false
  },
  {
    label: 'School Name',
    key: 'schoolName',
    order: 23,
    show: false
  },
  {
    label: 'Admitting Provider',
    key: 'admitProvider',
    order: 24,
    show: false
  },
  {
    label: 'Clinic',
    key: 'clinic',
    order: 25,
    show: false
  },
  {
    label: 'Admitting Clinic',
    key: 'admitClinic',
    order: 26,
    show: false
  },
  {
    label: 'Chart',
    key: 'chart',
    order: 27,
    show: false
  },
  {
    label: ColumnGlobalTitle.accountNumber,
    key: 'patientId',
    order: 28,
    show: false
  },
  {
    label: 'Modify',
    key: 'edit',
    order: 29,
    show: false
  },
  {
    label: 'Comments',
    key: 'comments',
    order: 30,
    show: true
  },
  {
    label: 'Eligibility',
    key: 'eligibility',
    order: 31,
    show: true
  },
  {
    label: `List name (Only for ${ListTypeNames()[TypeListEnum.triage]})`,
    key: 'listName',
    order: 32,
    show: true
  }
];

export const checkColumnsList = (
  columnsFromAPI: IColumnSet[],
  defaultColumns: IColumnSet[]
): IColumnSet[] => {
  let fullColumnsSet = [...columnsFromAPI];
  // cheking columns labels
  fullColumnsSet.forEach((column) => {
    const defaultValue = defaultColumns.find((item) => item.key === column.key);
    if (defaultValue && defaultValue.label !== column.label) {
      column.label = defaultValue.label;
    }
  });
  const columnsFromAPIKeys = columnsFromAPI.map((item) => item.key);
  defaultColumns.forEach((item) => {
    if (!columnsFromAPIKeys.includes(item.key)) {
      fullColumnsSet.push({
        ...item,
        show: false,
        order: fullColumnsSet.length + 1
      });
    }
  });
  return fullColumnsSet;
};

const initState: IActivityAppointments = {
  appointments: [],
  roomTypes: null,
  acuityTypes: null,
  emrTypes: null,
  dateRows: [],
  filters: {
    providersCodes: [],
    clinicCodes: [],
    fromDate: moment(new Date()).format('MM/DD/YYYY'),
    toDate: moment(new Date()).format('MM/DD/YYYY'),
    status: [AppointmentStatus.all],
    showCancelled: false,
    views: [TypeListEnum.roster, TypeListEnum.triage, 'appointments']
  },
  pagination: {
    page: null,
    pageSize: null,
    dataLength: null,
    dataCount: null
  },
  loading: false
};

export interface IFilters {
  providersCodes: string[];
  clinicCodes: string[];
  fromDate: string;
  toDate: string;
  status: string[];
  views: TAppointmentViews[];
  showCancelled: boolean;
  rosterColumns?: any[];
}

export interface IColumn {
  key: string;
  label: string;
  order: number;
  show: boolean;
}

interface IActivityAppointments {
  appointments: null | any[];
  dateRows: any[];
  filters: IFilters;
  roomTypes: null | any[];
  acuityTypes: null | any[];
  emrTypes: null | any[];
  pagination: {
    page: null | number;
    pageSize: null | number;
    dataLength: null | number;
    dataCount: null | number;
  };
  loading: boolean;
}

interface IAction {
  type: string;
  payload: any;
}

export const ActivityAppointmentsReducer = (
  state: IActivityAppointments = initState,
  action: IAction
) => {
  switch (action.type) {
    case SET_APPOINTMENTS: {
      return { ...state, appointments: action.payload };
    }
    case SET_DATEROWS: {
      return { ...state, dateRows: action.payload };
    }
    case SET_ROOM_TYPES: {
      return { ...state, roomTypes: action.payload };
    }
    case SET_ACUITY_TYPES: {
      return { ...state, acuityTypes: action.payload };
    }
    case SET_EMR_TYPES: {
      return { ...state, emrTypes: action.payload };
    }
    case SET_FILTERS: {
      return { ...state, filters: action.payload };
    }
    case SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case SET_PAGINATION_DATA: {
      const newPagination = { ...state.pagination, ...action.payload };
      return { ...state, pagination: newPagination };
    }
    default:
      return state;
  }
};
