import _ from 'lodash';
import { IMainValuePairs } from './types';

export interface IStateReducer {
    portalData: {
        main: null | IMainValuePairs
    },
    filter: IFilter
}

export interface IFilter {
    clinicId: null | number
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
}

const prefix = 'PATIENT_PORTAL_SETUP_';

export const actionsTypes = {
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_MAIN_PORTAL_DATA: `${prefix}SET_MAIN_PORTAL_DATA`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    portalData: {
        main: null
    },
    filter: {
        clinicId: null
    }
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const actions: IActions = {
        [actionsTypes.SET_FILTER]: () => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                }
            };
        },
        [actionsTypes.SET_MAIN_PORTAL_DATA]: () => {
            return {
                ...state,
                portalData: {
                    ...state.portalData,
                    main: action.payload
                }
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;
