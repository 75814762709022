export interface IStateReducer {
    filter: any;
    summary: IPatientChartMenu[];
    userProjectionSummary: IPatientChartMenu[];
}

export interface IAction {
    type: string;
    payload: any;
}

export interface IPatientChartMenu {
    id: string
    name: string
    menuItemType: EnumMenuItemType
    order: number
    noteTypeSeedIds?: number[] | null
    isActive?: boolean
    legacyNoteClinicIds?: null | number[]
}

export enum EnumMenuItemType {
    system = 'system',
    custom = 'custom'
}

export enum EnumPatientMenuSystemIds {
    PersonalDetails = 'PersonalDetails',
    Messages = 'Messages',
    AppointmentHistory = 'AppointmentHistory',
    Compliance = 'Compliance',
    Dashboard = 'Dashboard',
    AllNotes = 'AllNotes',
    NotesOfConcern = 'NotesOfConcern',
    Forms = 'Forms',
    ToDo = 'ToDo'
}