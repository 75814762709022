import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { IFact, IFactDocument } from './Facts/Facts/Reducer';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';
import { Alert } from "../../Framework/Common/Notification";
import store from 'src/store';

export const ActionManager = {
    UPDATE_SUBJECT_OVERVIEW: "UPDATE_SUBJECT_OVERVIEW",

    ADD_SUBJECT_OVERVIEW: "ADD_SUBJECT_OVERVIEW",
    ADD_MESSAGES_OVERVIEW_INBOX: "ADD_MESSAGES_OVERVIEW_INBOX",
    ADD_MESSAGES_OVERVIEW_SENT: "ADD_MESSAGES_OVERVIEW_SENT",
    UPDATE_MESSAGES_READ_STATUS: "UPDATE_MESSAGES_READ_STATUS",
    UPDATE_MESSAGES_FLAG_STATUS: "UPDATE_MESSAGES_FLAG_STATUS",
    ADD_MESSAGE: "ADD_MESSAGE",
    ADD_FACT_META_DATA: "ADD_FACT_META_DATA",
    ADD_FACT_USER_PREFERENCES: "ADD_FACT_USER_PREFERENCES",
    SAVE_FACT_USER_PREFERENCES: "SAVE_FACT_USER_PREFERENCES",
    UPDATE_MEMO: "UPDATE_MEMO",
    FLAG_FACT: "FLAG_FACT",
    UPDATE_FACT_VERFICATION_STATUS: "UPDATE_FACT_VERFICATION_STATUS",
    UPDATE_MASS_VERIFICATION: "UPDATE_MASS_VERIFICATION",
    UPDATE_INDIVIDUAL_STATUS: "UPDATE_INDIVIDUAL_STATUS",
    UPDATE_FACT_EFFECTIVE_DATE: "UPDATE_FACT_EFFECTIVE_DATE",
    UPDATE_GENERATE_REPORT_PDF: "UPDATE_GENERATE_REPORT_PDF",
    ADD_GENERATE_REPORT_PDF: "ADD_GENERATE_REPORT_PDF",
    REMOVE_SELECTED_FACTS: "REMOVE_SELECTED_FACTS",
    ADD_NEW_FILE: "ADD_NEW_FILE",
    FULL_SCREEN_ENABLE_DISABLE: "FULL_SCREEN_ENABLE_DISABLE",
    ADD_EXECUTION_RESULTS_FOR_PATIENT: "ADD_EXECUTION_RESULTS_FOR_PATIENT",
    ADD_FACT_TO_HIGHLIGHT: "ADD_FACT_TO_HIGHLIGHT"
}

let subjectsOverview: any = localStorage.getItem('subjectsOverview')

if (subjectsOverview) {
    subjectsOverview = JSON.parse(subjectsOverview)
} else {
    subjectsOverview = []
}

const initialState = {
    pagination: {
        subjectMessages: {},
        subjectMessagesSent: {},
        documents: {},
        facts: {}
    },
    overview: {},
    subjectsOverview,
    messagesOverview: [],
    messagesOverviewSent: [],
    factOverview: {},
    complianceOverview: {},
    documentsOverview: [],
    factsOverview: [],
    userPreferences: {},
    fullScreen: false,
    message: {
        data: {},
        messageDetailId: null
    },
    report: [],
    patientExecutionResults: {},
};


export interface ISubjectOverview {
    cohorts: ICohortDetails[];
    contactInfo: ISubjectContactInfo;
    name: ISubjectNameInfo;
    other: ISubjectOtherInfo;
    members: number;
    personalInfo: ISubjectPersonalInfo;
    subjectId: number;
    otherId: any;
    required: number;
    nonRequired: number;
    compliance: ISubjectCompliance;
}

interface ILastReviewed {
    id: number
    needsReview: number
    patientId: number
    reviewStatus: string
    reviewed: boolean
    reviewedDate: string
    userName: string
}

interface ISubjectCompliance {
    complianceRate: number;
    complianceStatus: string;
    complianceStatusDate: string;
    lastReviewed: ILastReviewed
}
//Facts and document details
export interface IFactDetails {
    factsList: IFactDocument[];
    subjectId: number;
    factsToHighLight: any[];
}


export interface IMessagesOverview {
    subjectId: number,
    totalMessages: number,
    unreadMessages: number,
    messageList: IMessageDetails[],
}

//Dashboard details
export interface IDocumentDetail {
    documentdescription: string;
    name: string | undefined;
    description: string,
    documentId: number,
    documentName: string,
    flagged: boolean,
    verified: boolean,
    reviewedDate: Date,
    ticketDetailId: number,
    txnCodeDescription: string,
    uploadedDate: Date,
}

export interface IFactOverview {
    flaggedCount: number,
    snoozedCount: number,
    totalfactCount: number,
    verifiedCount: number,
}

export interface IComplianceOverview {
    complianceRate: number,
    complianceStatus: number
}

export interface IMessageDetails {
    messageDetailId: number,
    userId: number,
    from: IMessageNameDetails,
    to: IMessageNameDetails,
    subject: string,
    readDate: Date,
    readStatus: boolean,
    receiveDate: string,
    flagForFollowup: boolean,
    followupDate: any,
}


export interface IMessage {
    contextInfo: null,
    uniqueDocumentName: string,
    document: null,
    clinicId: number,
    flagForFollowup: boolean,
    followupDate: any,
    messageText: string,
    readReciept: boolean,
    sessionUserId: number,
    subject: string,
    from: IMessageToDetails,
    to: IMessageToDetails[],
    userId: number,
}

interface IMessageNameDetails {
    lastName: string,
    firstName: string,
    middleName: string,
    fullName: string,
    personId: number,
    personType: string,
    picture: null,
}

interface IMessageToDetails {
    personId: number,
    personType: string,
    picture: null,
}

interface ICohortDetails {
    cohortId: number;
    cohortName: string;
}

interface ISubjectContactInfo {
    email: string;
    mobile: number;
}

interface ISubjectNameInfo {
    firstName: string;
    lastName: string;
    middleName: string;
    nickName: string;
    proNoun: string;
}

interface ISubjectOtherInfo {
    activeStatus: string;
    class: string;
    enrollmentDate: string;
    international: boolean;
    residency: string;
    school: string;
    standing: string;
    status: string;
    comment: string;
    chartNo: string;
    immunizationStatus: boolean;
}

interface ISubjectPersonalInfo {
    age: number;
    dob: Date;
    gender: string;
    sex: string;
}

interface IAction {
    type: string;
    payload: any;
    pagination: any;
    messageDetailId: any;
    documentId: any,
    subjectId: any,
    result: any,
    messageType: any;
    comment: string;
    includedIds: any[],
    excludedIds: any[],
    summary: any,
    fact: any,
    document: any,
}

const SubjectReducer = (
    state: any = { ...initialState },
    action: IAction
) => {
    switch (action.type) {
        case ActionManager.UPDATE_SUBJECT_OVERVIEW: {
            let newState = _.cloneDeep(state)
            const index = newState.subjectsOverview.findIndex((o: ISubjectOverview) => o.subjectId === action.payload.subjectId);
            newState.subjectsOverview[index] = action.payload;
            newState.overview = { ...action.payload };
            localStorage.setItem('subjectsOverview', JSON.stringify(newState.subjectsOverview))
            return newState;
        }
        case ActionManager.ADD_SUBJECT_OVERVIEW: {
            let newState = _.cloneDeep(state)
            let index = newState.subjectsOverview.findIndex((o: ISubjectOverview) => o.subjectId === action.payload.subjectOverview.subjectId);
            if (index === -1) {
                newState.subjectsOverview.push(action.payload.subjectOverview);
            }
            else {
                newState.subjectsOverview[index] = action.payload.subjectOverview;
                newState.subjectOverview = action.payload.subjectOverview;
            }
            newState.overview = { ...action.payload };
            localStorage.setItem('subjectsOverview', JSON.stringify(newState.subjectsOverview))
            return newState;
        }
        case ActionManager.UPDATE_MEMO: {
            let newState = _.cloneDeep(state)
            let index = newState.subjectsOverview.findIndex((o: ISubjectOverview) => o.subjectId === action.payload.subjectId);
            if (index !== -1) {
                newState.subjectsOverview[index].other.comment = action.payload.comment;
            }
            return newState;
        }
        case ActionManager.ADD_NEW_FILE: {
            let newState = _.cloneDeep(state)
            newState.overview.documentOverView.totaldocumentsCount += 1;
            return newState;
        }
        case ActionManager.ADD_MESSAGES_OVERVIEW_INBOX: {
            let newState = _.cloneDeep(state)
            let indexM = newState.messagesOverview.findIndex((m: IMessagesOverview) => m.subjectId === action.payload.subjectId);

            if (action.pagination.current > 1) {
                let isExist = isExistMessage(newState.messagesOverview[indexM].messageList, action.payload.messageList);
                if (isExist) return state;
                newState.messagesOverview[indexM].messageList = [...newState.messagesOverview[indexM].messageList, ...action.payload.messageList]
            } else {
                if (indexM === -1) {
                    newState.messagesOverview.push(action.payload)
                } else {
                    newState.messagesOverview[indexM] = action.payload
                }
            }
            newState.pagination.subjectMessages = action.pagination;

            return newState;
        }

        case ActionManager.ADD_MESSAGES_OVERVIEW_SENT: {
            let newState = _.cloneDeep(state)
            let indexM = newState.messagesOverviewSent.findIndex((m: IMessagesOverview) => m.subjectId === action.payload.subjectId);

            if (action.pagination.current > 1) {
                let isExist = isExistMessage(newState.messagesOverviewSent[indexM].messageList, action.payload.messageList);
                if (isExist) return state;
                newState.messagesOverviewSent[indexM].messageList = [...newState.messagesOverviewSent[indexM].messageList, ...action.payload.messageList]
            } else {
                if (indexM === -1) {
                    newState.messagesOverviewSent.push(action.payload)
                } else {
                    newState.messagesOverviewSent[indexM] = action.payload
                }
            }
            newState.pagination.subjectMessagesSent = action.pagination;

            return newState;
        }

        case ActionManager.UPDATE_MESSAGES_READ_STATUS: {
            let newState = _.cloneDeep(state)
            if (action.messageType === "To Subject") {
                let indexM = newState.messagesOverview.findIndex((m: IMessagesOverview) => m.subjectId === action.payload.subjectId);
                let indexL = newState.messagesOverview[indexM].messageList.findIndex((m: IMessageDetails) => m.messageDetailId === action.payload.messageId);
                let currentDate = new Date;
                if (indexM !== -1 && indexL !== -1) {
                    if (action.payload.readStatus) {
                        newState.messagesOverview[indexM].unreadMessages = newState.messagesOverview[indexM].unreadMessages > 0 ? newState.messagesOverview[indexM].unreadMessages - 1 : 0;
                        newState.messagesOverview[indexM].messageList[indexL].readDate = currentDate;
                    } else {
                        newState.messagesOverview[indexM].unreadMessages = newState.messagesOverview[indexM].unreadMessages < newState.messagesOverview[indexM].totalMessages ?
                            newState.messagesOverview[indexM].unreadMessages + 1 : newState.messagesOverview[indexM].unreadMessages;

                    }
                    newState.messagesOverview[indexM].messageList[indexL].readStatus = action.payload.readStatus;
                }
            } else {
                let indexM = newState.messagesOverviewSent.findIndex((m: IMessagesOverview) => m.subjectId === action.payload.subjectId);
                let indexL = newState.messagesOverviewSent[indexM].messageList.findIndex((m: IMessageDetails) => m.messageDetailId === action.payload.messageId);
                let currentDate = new Date().toISOString();
                if (indexM !== -1 && indexL !== -1) {
                    if (action.payload.readStatus) {
                        newState.messagesOverviewSent[indexM].unreadMessages = newState.messagesOverviewSent[indexM].unreadMessages > 0 ? newState.messagesOverviewSent[indexM].unreadMessages - 1 : 0;
                        newState.messagesOverviewSent[indexM].messageList[indexL].readDate = currentDate;
                    } else {
                        newState.messagesOverviewSent[indexM].unreadMessages = newState.messagesOverviewSent[indexM].unreadMessages < newState.messagesOverviewSent[indexM].totalMessages ?
                            newState.messagesOverviewSent[indexM].unreadMessages + 1 : newState.messagesOverviewSent[indexM].unreadMessages;

                    }
                    newState.messagesOverviewSent[indexM].messageList[indexL].readStatus = action.payload.readStatus;
                }
            }

            return newState;
        }

        case ActionManager.UPDATE_MESSAGES_FLAG_STATUS: {
            let newState = _.cloneDeep(state)
            if (action.messageType === "To Subject") {
                let indexM = newState.messagesOverview.findIndex((m: IMessagesOverview) => m.subjectId === action.payload.subjectId);
                let indexL = newState.messagesOverview[indexM].messageList.findIndex((m: IMessageDetails) => m.messageDetailId === action.payload.messageId);
                if (indexM !== -1 && indexL !== -1) {
                    newState.messagesOverview[indexM].messageList[indexL].flagForFollowup = action.payload.flagForFollowup;
                }
            } else {
                let indexM = newState.messagesOverviewSent.findIndex((m: IMessagesOverview) => m.subjectId === action.payload.subjectId);
                let indexL = newState.messagesOverviewSent[indexM].messageList.findIndex((m: IMessageDetails) => m.messageDetailId === action.payload.messageId);
                if (indexM !== -1 && indexL !== -1) {
                    newState.messagesOverviewSent[indexM].messageList[indexL].flagForFollowup = action.payload.flagForFollowup;
                }
            }
            return newState;
        }


        case ActionManager.ADD_MESSAGE: {
            let newState = _.cloneDeep(state)
            newState.message.data = action.payload;
            newState.message.messageDetailId = action.messageDetailId;
            return newState;
        }

        case ActionManager.ADD_FACT_TO_HIGHLIGHT: {
            let newState = _.cloneDeep(state)
            let indexM = newState.factsOverview.findIndex((m: IFactDetails) => m.subjectId === action.subjectId);
            newState.factsOverview[indexM].factsToHighLight = action.payload.validFactIds.concat(action.payload.invalidFactIds);
            let scrolIndex = newState.factsOverview[indexM].factsList.length - 1;

            newState.factsOverview[indexM].factsList.map((f: any, indexL: number) => {
                let matched = -1;
                let invalidIndex = -1;
                newState.factsOverview[indexM].factsToHighLight.map((id: number) => {
                    if (f.ticketDetailId === id && f.factType !== 2) {
                        if (indexL < scrolIndex) {
                            scrolIndex = indexL;
                        }
                        matched = indexL;
                        if (action.payload.invalidFactIds.length > 0) {
                            let invalid = action.payload.invalidFactIds.indexOf(f.ticketDetailId);
                            if (invalid !== -1) {
                                invalidIndex = indexL;
                            }
                        }
                    }

                }
                )
                if (matched >= 0) {
                    newState.factsOverview[indexM].factsList[indexL].highlighted = true;
                    newState.factsOverview[indexM].factsList[indexL].compliantFact = (f.ticketDetailId === action.payload.complianceFactId);
                } else {
                    newState.factsOverview[indexM].factsList[indexL].highlighted = false;

                }
                if (invalidIndex >= 0) {
                    newState.factsOverview[indexM].factsList[invalidIndex].invalid = true;
                } else {
                    newState.factsOverview[indexM].factsList[indexL].invalid = false;
                }

                if (scrolIndex >= 0) {
                    newState.factsOverview[indexM].scrollToId = newState.factsOverview[indexM].factsList[scrolIndex].ticketDetailId
                }
            })
            return newState;
        }
        case ActionManager.FULL_SCREEN_ENABLE_DISABLE: {
            let newState = _.cloneDeep(state)
            newState.fullScreen = !newState.fullScreen;
            return newState;
        }
        case ActionManager.ADD_FACT_META_DATA: {
            let newState = _.cloneDeep(state)
            newState.pagination.facts = action.pagination;
            var indexM = -1;
            newState.factsOverview.map((m: any, index: number) => {
                if (m.subjectId === action.payload.subjectId) {
                    indexM = index
                }
            })
            if (action.pagination.current > 1) {
                newState.factsOverview[indexM].factsList = [...newState.factsOverview[indexM].factsList, ...action.payload.factsList]
                if (newState.factsOverview[indexM].factsToHighLight && newState.factsOverview[indexM].factsToHighLight.length > 0) {
                    newState.factsOverview[indexM].factsToHighLight.map((id: any) => {
                        let indexL = newState.factsOverview[indexM].factsList.findIndex((f: any) =>
                            f.ticketDetailId == id && f.factType !== 2
                        )
                        if (indexL && indexL !== -1) {
                            newState.factsOverview[indexM].factsList[indexL].highlighted = true;
                            newState.factsOverview[indexM].factsList[indexL].compliantFact = (newState.factsOverview[indexM].factsList[indexL].ticketDetailId === newState.factsOverview[indexM].factsToHighLight.complianceFactId);
                        } else {
                            newState.factsOverview[indexM].factsList[indexL].highlighted = false;
                            newState.factsOverview[indexM].factsList[indexL].compliantFact = false;
                        }
                    }
                    )

                }
            } else {
                if (indexM === -1) {
                    newState.factsOverview.push(action.payload)
                } else {
                    newState.factsOverview[indexM].factsList = action.payload.factsList;
                    if (newState.factsOverview[indexM].factsToHighLight && newState.factsOverview[indexM].factsToHighLight.length > 0) {
                        newState.factsOverview[indexM].factsToHighLight.map((id: any) => {
                            let indexL = newState.factsOverview[indexM].factsList.findIndex((f: any) =>
                                f.ticketDetailId == id && f.factType !== 2
                            )
                            if (indexL && indexL !== -1) {
                                newState.factsOverview[indexM].factsList[indexL].highlighted = true;
                                newState.factsOverview[indexM].factsList[indexL].compliantFact = (newState.factsOverview[indexM].factsList[indexL].ticketDetailId === newState.factsOverview[indexM].factsToHighLight.complianceFactId);
                            } else {
                                newState.factsOverview[indexM].factsList[indexL].highlighted = false;
                                newState.factsOverview[indexM].factsList[indexL].compliantFact = false;
                            }
                        }
                        )

                    }
                }
            }
            return newState;
        }

        case ActionManager.ADD_FACT_USER_PREFERENCES: {
            let newState = _.cloneDeep(state)
            newState.userPreferences = action.payload;
            return newState;
        }
        case ActionManager.SAVE_FACT_USER_PREFERENCES: {
            let newState = _.cloneDeep(state)
            newState.userPreferences.value = action.payload;
            return newState;
        }
        case ActionManager.FLAG_FACT: {
            let newState = _.cloneDeep(state)
            let indexM = newState.factsOverview.findIndex((m: IFactDetails) => m.subjectId === action.payload.subjectId);
            let indexL = newState.factsOverview[indexM].factsList.findIndex((m: IFact) => m.ticketDetailId === action.payload.ticketDetailId);
            newState.factsOverview[indexM].factsList[indexL].flagged = !newState.factsOverview[indexM].factsList[indexL].flagged;
            newState.overview.factOverView.flaggedCount = action.result.flaggedCount;
            return newState;
        }
        case ActionManager.UPDATE_FACT_VERFICATION_STATUS: {
            let newState = _.cloneDeep(state)
            let indexM = newState.factsOverview.findIndex((m: IFactDetails) => m.subjectId === action.payload.subjectId);
            let indexL = newState.factsOverview[indexM].factsList.findIndex((m: IFact) => m.ticketDetailId === action.payload.ticketDetailId);
            newState.factsOverview[indexM].factsList[indexL].verified = !newState.factsOverview[indexM].factsList[indexL].verified;
            newState.overview.factOverView.verifiedCount = action.result.verifiedCount;
            return newState;
        }
        case ActionManager.REMOVE_SELECTED_FACTS: {
            let newState = _.cloneDeep(state)
            let indexM = newState.factsOverview.findIndex((m: IFactDetails) => m.subjectId === action.payload.subjectId);
            action.payload.request.forEach((factDoc: any) => {
                let index = newState.factsOverview[indexM].factsList.findIndex((i: any) => i.ticketDetailId === factDoc.ticketDetailId);
                if (index !== -1) {
                    newState.factsOverview[indexM].factsList.splice(index, 1);
                }
            })

            return { ...newState };
        }

        case ActionManager.UPDATE_INDIVIDUAL_STATUS: {
            let newState = _.cloneDeep(state)
            let indexM = newState.factsOverview.findIndex((m: IFactDetails) => m.subjectId === action.subjectId);
            let indexL = newState.factsOverview[indexM].factsList.findIndex((m: IFact) => m.factId === action.fact.factId);
            newState.factsOverview[indexM].factsList[indexL] = { ...action.fact }
            newState.overview.factOverView.verifiedCount = action.summary.verifiedCount;
            newState.overview.factOverView.flaggedCount = action.summary.flaggedCount;
            return { ...newState };
        }

        case ActionManager.UPDATE_MASS_VERIFICATION: {
            let newState = _.cloneDeep(state)
            let indexM = newState.factsOverview.findIndex((m: IFactDetails) => m.subjectId === action.payload);
            if (action.includedIds.length > 0) {

                newState.factsOverview[indexM].factsList.map((f: any) => {
                    if (action.includedIds.indexOf(f.ticketDetailId) !== -1) {
                        f.verified = true;
                    }
                })

            } else if (action.excludedIds.length > 0) {
                newState.factsOverview[indexM].factsList.map((f: any) => {
                    if (action.excludedIds.indexOf(f.ticketDetailId) === -1) {
                        f.verified = true;
                    }
                })

            } else {
                newState.factsOverview[indexM].factsList.map((f: any) => f.verified = true);
            }
            newState.overview.factOverView.verifiedCount = action.summary.verifiedCount;
            return newState;
        }
        case ActionManager.UPDATE_FACT_EFFECTIVE_DATE: {
            let newState = _.cloneDeep(state)
            let indexM = newState.factsOverview.findIndex((m: IFactDetails) => m.subjectId === action.payload.subjectId);
            let indexL = newState.factsOverview[indexM].factsList.findIndex((m: IFact) => m.ticketDetailId === action.payload.ticketDetailId);
            newState.factsOverview[indexM].factsList[indexL].effectiveDate = action.payload.effectiveDate;
            return newState;
        }
        case ActionManager.UPDATE_GENERATE_REPORT_PDF: {
            let newState = _.cloneDeep(state)
            newState.report = action.payload;
            return newState;
        }
        case ActionManager.ADD_EXECUTION_RESULTS_FOR_PATIENT: {
            let newState = _.cloneDeep(state)
            newState.patientExecutionResults[action.subjectId] = action.payload;
            return newState;
        }
        default: {
            return state;
        }

    }
};
export default SubjectReducer;

export const addSubjectOverview = (data: any) => {
    return {
        type: ActionManager.ADD_SUBJECT_OVERVIEW,
        payload: data
    }
};


export const fullScreenToggle = () => {
    return {
        type: ActionManager.FULL_SCREEN_ENABLE_DISABLE,
    }
};

export const addGenerateReportPdf = (report: any[]) => {
    return {
        type: ActionManager.ADD_GENERATE_REPORT_PDF,
        payload: {
            report
        }
    };
};

export const addMessagesOverview = (data: any, pagination: any, direction: string) => {
    return {
        type: direction === "in" ? ActionManager.ADD_MESSAGES_OVERVIEW_INBOX : ActionManager.ADD_MESSAGES_OVERVIEW_SENT,
        payload: data,
        pagination: pagination
    }
};

export const addMessage = (data: any, messageDetailId: number) => {
    return {
        type: ActionManager.ADD_MESSAGE,
        payload: data,
        messageDetailId: messageDetailId,
    }
};


export const updateMessagesOverview = (data: any, type: string, messageType: string) => {
    return {
        type: type === "read" ? ActionManager.UPDATE_MESSAGES_READ_STATUS : ActionManager.UPDATE_MESSAGES_FLAG_STATUS,
        payload: data,
        messageType: messageType,
    }
};

export const getSubjectOverview = (data: any) => {
    return APIHandler.AxiosInstance.get(ApiUrl.GET_SUBJECT_OVERVIEW + `?subjectId=${data.subjectId}`, { withCredentials: true });
};

export const generateReportPdf = (reportName: string, recordSelectionFormula: string) => {
    return APIHandler.AxiosInstance.post(
        ApiUrl.GENERATE_REPORT_PDF, {
        reportName: reportName,
        recordSelectionFormula: recordSelectionFormula
    },
        { withCredentials: true }
    );
};

const updateMemo = (comment: any, subjectId: any) => {
    return {
        type: ActionManager.UPDATE_MEMO,
        payload: {
            comment: comment,
            subjectId: subjectId
        }
    }
};

const addFactMetaData = (result: any, pagination: any) => {
    return {
        type: ActionManager.ADD_FACT_META_DATA,
        payload: result,
        subjectId: result.subjectId,
        pagination: pagination
    };
};

const addFactUserPreferences = (result: any,) => {
    return {
        type: ActionManager.ADD_FACT_USER_PREFERENCES,
        payload: result,
        subjectId: result.subjectId,
    };
};


const saveFactUserPreferences = (result: any,) => {
    return {
        type: ActionManager.SAVE_FACT_USER_PREFERENCES,
        payload: result,
        subjectId: result.subjectId,
    };
};


export const flagFact = (data: any, result: any) => {
    return {
        type: ActionManager.FLAG_FACT,
        payload: data,
        result: result,
    };
};

export const updateFactEffectiveDateAction = (data: any, result: any) => {
    return {
        type: ActionManager.UPDATE_FACT_EFFECTIVE_DATE,
        payload: data,
        result: result,
    };
};


export const updateMassVerification = (subjectId: any, includedIds: any[], excludedIds: any[], summary: any) => {
    return {
        type: ActionManager.UPDATE_MASS_VERIFICATION,
        payload: subjectId,
        includedIds: includedIds,
        excludedIds: excludedIds,
        summary: summary,
    };
};

export const updateIndividualStatus = (subjectId: any, fact: any, summary: any) => {
    return {
        type: ActionManager.UPDATE_INDIVIDUAL_STATUS,
        subjectId: subjectId,
        fact: fact,
        summary: summary,
    };
};

export const updateFactVericationStatusAction = (data: any, result: any) => {
    return {
        type: ActionManager.UPDATE_FACT_VERFICATION_STATUS,
        payload: data,
        result: result,
    }
};


export const removeSelectedFacts = (data: any, subjectId: any) => {
    return {
        type: ActionManager.REMOVE_SELECTED_FACTS,
        payload: data,
        subjectId: subjectId,
    }
};

export const addNewFile = () => {
    return {
        type: ActionManager.ADD_NEW_FILE,
    }
};

const addExecutionResultsForPatient = (data: any, subjectId: any) => {
    return {
        type: ActionManager.ADD_EXECUTION_RESULTS_FOR_PATIENT,
        payload: data,
        subjectId: subjectId,
    }
};

export const factsToHighLight = (complianceFactId: any, validFactIds: any, invalidFactIds: any, subjectId: any) => {
    return {
        type: ActionManager.ADD_FACT_TO_HIGHLIGHT,
        payload: {
            complianceFactId: complianceFactId,
            validFactIds: validFactIds,
            invalidFactIds: invalidFactIds
        },
        subjectId: subjectId,
    }
};

export const getDocumentViewerList = (subjectId: number) => {
    let paginationDetails = {
        count: 0,
        filter: { "isFacts": false, "factType": ["immunization", "procedure"], "isDocuments": true },
        sortBy: 'name',
        startIndex: 0,
        subjectId: subjectId
    };

    return APIHandler.AxiosInstance.post(ApiUrl.GET_FACT_DOCUMENT_META_DATA, paginationDetails, { withCredentials: true })

};

export const getFactDocumentList = (
    currentPage = 1,
    count = 50,
    sortBy: any,
    sortType: any,
    filter: any,
    subjectId: number,
    callback: any,
): any => {
    let paginationDetails = {
        count: count,
        filter: filter,
        sortBy: sortBy === "result" ? "resultName" : sortBy,
        sortType: sortType,
        startIndex: (currentPage - 1) * 50,
        subjectId: subjectId
    };

    return (dispatch: any) => {
        APIHandler.AxiosInstance.post(ApiUrl.GET_FACT_DOCUMENT_META_DATA, paginationDetails, { withCredentials: true })
            .then(response => {
                let data: any = response.data;
                if (data.success) {
                    let pagination = {
                        total: data.result.total,
                        current: currentPage
                    };
                    let combinedData = {
                        factsList: data.result.summary,
                        subjectId: paginationDetails.subjectId
                    }
                    dispatch(addFactMetaData(combinedData, pagination));
                    callback();
                }
            })
            .catch(error => {
                dispatch(expiryCheck(error));
                console.log(error);
            });
    };
};

export const getFactUserPreferences = (
    preferences: any
): any => {
    let paginationDetails = {
        preferences: preferences,
    }
    return (dispatch: any) => {
        APIHandler.AxiosInstance.post(ApiUrl.GET_FACT_USER_PREFERENCES, paginationDetails, { withCredentials: true })
            .then(response => {
                let data: any = response.data;
                if (data.success) {
                    dispatch(addFactUserPreferences(data.result.summary[0]));
                }
            })
            .catch(error => {
                dispatch(expiryCheck(error));
                console.log(error);
            });
    };
};

export const getExecutionResultsByPatient = (
    subjectId: any,
    callback?: any

): any => {
    let paginationDetails = {
        subjectId: subjectId,
    }
    return async (dispatch: any) => {
        try {
            const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_EXECUTION_RESULT_BY_PATIENT, paginationDetails, { withCredentials: true })
            let data: any = response.data;
            if (data.success) {
                dispatch(addExecutionResultsForPatient(data.result, subjectId));
            }
        } catch (error) {
            dispatch(expiryCheck(error));
            console.log(error);
        } finally {
            callback();
        }
    };
};


export const ruleRefresh = async (
    subjectId: any,
    callback: any
) => {
    const paginationDetails = {
        subjectId,
    }
    const { dispatch }: any = store;
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.RULE_REFRESH, paginationDetails, { withCredentials: true })
        let data: any = response.data;
        if (data.success) {
            Alert("success", "Success", "Requirements refreshed successfully.");
        }
    } catch (error) {
        Alert("error", "Error", "Rule engine has encountered a problem refreshing the requirements");
        dispatch(expiryCheck(error));
        console.log(error);
    } finally {
        callback()
    }
};


export const updateFactUserPreferences = (
    preferences: any,
    savedPreferences: any
): any => {
    let paginationDetails = {
        preferences: [{
            ...savedPreferences,
            value: preferences,
        }],
    }
    return (dispatch: any) => {
        APIHandler.AxiosInstance.post(ApiUrl.SAVE_FACT_USER_PREFERENCES, paginationDetails, { withCredentials: true })
            .then(response => {
                let data: any = response.data;
                if (data.success) {
                    dispatch(saveFactUserPreferences(preferences));
                }
            })
            .catch(error => {
                dispatch(expiryCheck(error));
                console.log(error);
            });
    };
};

export const updatePatientComment = (
    patientId: any,
    comment: any,
    callback: any
): any => {
    let details = {
        patientId: patientId,
        Comment: comment,
    }
    return (dispatch: any) => {
        APIHandler.AxiosInstance.post(ApiUrl.UPDATE_PATIENT_COMMENT, details, { withCredentials: true })
            .then(response => {
                let data: any = response.data;
                if (data.success) {
                    callback();
                    dispatch(updateMemo(comment, patientId));
                }
            })
            .catch(error => {
                dispatch(expiryCheck(error));
                console.log(error);
            });
    };
};

function isExistMessage(stateMessages: any, actionMessages: any): boolean {
    if (actionMessages && actionMessages.length > 0) {
        let action_message: any = actionMessages[0];
        let isExist: any = stateMessages.find((message: any) => {
            return message.messageDetailId === action_message.messageDetailId;
        });
        if (isExist) return true;
    }
    return false;
}


