import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';

import Collapse from 'src/Framework/Controls/CollapseA11y';
import TableRow, { IPropsType } from '../TableRow';

import { Title, Container } from './styled';

const { Panel } = Collapse;

const Component = <T,>(props: IPropsType<T>) => {
  const { value, containerRef } = props;
  const resizeRef = useRef<ResizeObserver>();
  const currentContainerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(value.batchSettings?.defaultOpen || false);
  const onOpenChange = useCallback(() => {
    setOpen((v) => {
      const checked = !v;
      if (value.batchSettings?.onChangeOpen) {
        value.batchSettings.onChangeOpen(checked);
      }
      return checked;
    });
  }, []);

  useEffect(() => {
    // LOGIC FOR STICKY HEADER
    // DO NOT TOUCH IT!
    const scrollElement = containerRef?.current?.offsetParent;
    const header = currentContainerRef.current?.querySelector(
      '.ant-collapse-header'
    ) as any;

    if (header && scrollElement) {
      let scrollWidth = scrollElement.scrollWidth || 0;
      let clientWidth = scrollElement.clientWidth || 0;

      const listener = (e: any) => {
        if (currentContainerRef.current) {
          window.requestAnimationFrame(() => {
            if (clientWidth + e.target.scrollLeft <= scrollWidth) {
              header.style.left = `${e.target.scrollLeft}px`;
            } else {
              header.style.left = `${scrollWidth - clientWidth}px`;
            }
          });
        }
      };
      const check = () => {
        // @ts-ignore
        header.style.width = `${scrollElement.offsetWidth}px`;
        header.style.left = `${scrollElement.scrollLeft}px`;
        scrollWidth = scrollElement.scrollWidth || 0;
        clientWidth = scrollElement.clientWidth || 0;
      };
      check();
      scrollElement.addEventListener('scroll', listener);
      if (resizeRef.current) {
        resizeRef.current?.unobserve(scrollElement);
        resizeRef.current?.disconnect();
      }
      resizeRef.current = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          check();
        });
      });
      resizeRef.current?.observe(scrollElement);
      return () => {
        scrollElement.removeEventListener('scroll', listener);
        resizeRef.current?.unobserve(scrollElement);
        resizeRef.current?.disconnect();
      };
    }
    return () => {};
  }, []);

  if (value.batchSettings) {
    const { title, rightComponent, children, id } = value.batchSettings;
    return (
      <Container ref={currentContainerRef}>
        <Collapse
          defaultActiveKey={value.batchSettings?.defaultOpen ? [id] : []}
          activeKey={open ? [id] : []}
          expandIconPosition={'left'}
          icon={'tableArrow'}
          uppercaseHeader={false}
          noSidePaddings={true}
          noVerticalPaddings={true}
          noHeaderSidePaddings={true}
          noBorder={true}
          onChange={onOpenChange}
        >
          <Panel
            className={'table-group-collapse'}
            key={id}
            header={
              <Row align="middle" gutter={8} justify="space-between">
                <Col>
                  <Title>{title}</Title>
                </Col>
                {rightComponent && <Col>{rightComponent}</Col>}
              </Row>
            }
          >
            {children.map((value, index) => {
              return (
                <TableRow
                  {...props}
                  key={`row-${value.id || index}`}
                  index={index}
                  value={value}
                />
              );
            })}
          </Panel>
        </Collapse>
      </Container>
    );
  }
  return null;
};

export default Component;
