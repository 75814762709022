import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"
import ApiSubscription, { BucketsList } from "src/Framework/ApiSubscription";

import { IAssessment } from './types'

import { actionsTypes } from './Reducer'
import { enablePatientPush } from '../../../../Subject/pushActions';

export const SubscriptionData = (patientId: string | number) => ({
    bucket: BucketsList.patientAssessment,
    filter: `${BucketsList.patientAssessment}.PatientId == ${patientId}`
})

export const onChangeSort = (patientId: string, payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload,
        patientId
    })
}

const setAssessments = (patientId: string | number, payload: any) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload,
        patientId
    })
}

export const onCreateUpdateItem = (patientId: string | number, payload: any) => {
    const data = { ...(store.getState().assessments[patientId]?.summary || {}) }
    if (data[payload.id]) {
        data[payload.id] = { ...data[payload.id], ...payload }
    } else {
        data[payload.id] = payload
    }
    setAssessments(patientId, data)
}

export const get = async (patientId: string) => {
    try {
        if (enablePatientPush) {
            const req = SubscriptionData(patientId)
            const response = await ApiSubscription.instance.FindAndSubscribe<IAssessment>(req)
            if (response?.success) {
                setAssessments(patientId, response.data)
                return response
            }
            return
        } else {
            const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_ASSESSMENTS}`, {
                withCredentials: true, params: {
                    patientId
                }
            })
            if (response.data.success) {
                const data = response.data.result.summary
                setAssessments(patientId, data)
                return data
            }
        }
        return
    } catch (e) {
        return
    }
}

export const post = async (data: any) => {
    try {
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_ASSESSMENTS}`, data, {
            withCredentials: true,
            interceptError: true
        })
        if (response.data.success) {
            const result = Object.values(response.data.result.summary)[0]
            if (!enablePatientPush) {
                onCreateUpdateItem(data.patientId, result)
            }
            return result
        }
        return
    } catch (e) {
        return
    }
}

export const patch = async (data: Partial<IAssessment>) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.GET_ASSESSMENTS}/${data.id}`, data, {
            withCredentials: true,
            interceptError: true
        })
        if (response.data.success && data.id) {
            if (!enablePatientPush) {
                const item = response.data.result.summary[data.id]
                onCreateUpdateItem(item.patient.id, item)
            }
            return data
        }
        return
    } catch (e) {
        return
    }
}