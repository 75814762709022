import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Main = styled.div`
  position: relative;
  visibility: inherit;

  ${({ disableClick }: any) =>
    !disableClick &&
    `
  cursor: pointer;
  `}
  ${(props: any) => {
    const icon = props.hoverIcon;
    if (icon) {
      return `&:hover, &:focus-visible {
                    .icon {
                    mask-image: url(${icon}) !important;
                }
            }`;
    }
    return '';
  }}
  ${(props: any) => {
    if (props.changeColorOnHover) {
      return ` &:focus-visible {
    div {
        background-color:${props.color} !important;
          .icon {
              background: ${
                props?.hoverColor ? props?.hoverColor : 'rgba(247, 247, 247, 1)'
              } !important;
            }
    }
           }`;
    } else {
      return '';
    }
  }}
  ${(props: any) => {
    const disabled = props.disabled;
    if (disabled) {
      return `
            filter: grayscale(1);
            pointer-events: none;
            opacity: 0.6;
            div {
                pointer-events: none;
            }
          `;
    }
    return '';
  }}
  .flat-icon {
    &:hover {
      div {
        background-color: ${globalStyleVariables.newBrandingPrimary200} !important;
      }
    }
  }
`;

export const Badge = styled.div`
  position: absolute;
  right: -3px;
  top: -3px;
  height: 18px;
  min-width: 18px;
  padding: 3px;
  border-radius: 9px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  z-index: 2;
`;

export const Container: any = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(247, 247, 247, 1);
  transition: all 0.25s ease-out;

  .icon {
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
    background: ${(props: any) => props.color};
  }

  &.disableBackground {
    background-color: unset;
  }

  ${({ disableClick }: any) =>
    !disableClick &&
    `
    cursor: inherit;
    &:active {
      transform: scale(0.8);
    }
  `}
  ${({ changeColorOnHover, disableClick, color, hoverColor }: any) => {
    if (changeColorOnHover && !disableClick) {
      return `
        &:hover {
          background-color:${color} !important;
          .icon {
               background: ${
                 hoverColor ? hoverColor : 'rgba(247, 247, 247, 1)'
               } !important;
          }
        }
      `;
    }
    return ``;
  }}
  ${(props: any) => {
    if (props.isCompleted) {
      return `
            filter: none;
            opacity: 1;
                background-color: ${globalStyleVariables.newBrandingPrimary100} !important;
                border: 1px solid ${props.color};
                .icon {
                    background: ${props.color} !important;
                  }
               `;
    }
    return '';
  }}
  &.enableBorder {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const Icon: any = styled.div`
  mask-image: url(${(props: any) => props.icon});
`;
