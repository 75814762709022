import moment, { Moment } from 'moment';
import _ from 'lodash';

import store from 'src/store';

import { convertProviders } from 'src/Activities/Home/Messages/utils';

import {
  setSelectedProviders,
  getDurationEndTime,
  calcDurationDateTime,
  getStandartTimeFromMilitaryTime,
  getStartTimeOpts,
  getEndTimeOpts,
  getMinFrom1200AM,
  getClinicStartEndTimeInterval,
  getTime
} from 'src/Activities/Schedule/Utils/Utils';
import { ClinicSimpleQueryResult } from 'src/App/Admin/Pages/Clinics/store/types';
import { getClinicConfig } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';

export interface IProviderCode {
  id: number;
  providerId: number;
  providerCode: string;
  page: number;
  name: string;
  code: string;
}

export const generateCodesByProvidersCodes = (
  values: string[],
  clinicId: number
): IProviderCode[] => {
  const providers = Object.values(store.getState().user.providers.summary);
  const existProviders: IProviderCode[] = [];
  values.forEach((code) => {
    const provider = providers.find((v) => v.code === code);
    if (provider) {
      existProviders.push({
        id: provider.providerId,
        providerId: provider.providerId,
        providerCode: provider.code,
        page: getClinicConfig(provider, clinicId)?.maxPages || 1,
        name: `${provider.firstName} ${provider.lastName}`,
        code: provider.code
      });
    }
  });
  return existProviders;
};

function validateTimeInterval(
  eventMinutes: number,
  regularMeetingTime: number
) {
  const startMod = eventMinutes % regularMeetingTime;
  if (startMod > 0) {
    const startDiv = Math.floor(eventMinutes / regularMeetingTime);
    return startDiv * regularMeetingTime;
  }
  return eventMinutes;
}

export function setEventEndTime(payload: any, clinic: any, endTimeValue: any) {
  let updatedPayload = { ...payload };
  let endDate = moment(updatedPayload.scheduleEvent.scheduleEventDateTime);
  let newEndTime =
    moment(endDate).format('YYYY-MM-DD') + 'T' + endTimeValue + ':00.000';

  newEndTime = validateEndTimeRule(
    clinic,
    updatedPayload.scheduleEvent.scheduleEventDateTime,
    newEndTime
  );

  updatedPayload.scheduleEvent.endTime = newEndTime;

  let inSeries = updatedPayload.scheduleEvent.series?.typeFrequencyId > 0;
  if (!updatedPayload.scheduleEvent.series.endTime || !inSeries) {
    updatedPayload.scheduleEvent.series.endTime =
      updatedPayload.scheduleEvent.endTime;
  }

  return updatedPayload;
}

export function validateEndTimeRule(
  clinic: ClinicSimpleQueryResult,
  startDateTime: any,
  endDateTime: any,
  clear: boolean = false
) {
  let interval = getClinicStartEndTimeInterval(clinic);
  var eventEndTime = endDateTime;
  if (eventEndTime) {
    let startMinutes: number = getMinFrom1200AM(interval.startTime);
    let endMinutes: number = getMinFrom1200AM(interval.endTime);
    let endMomentTime: string = moment(eventEndTime).format('HH:mm');
    let startMomentTime: string = moment(startDateTime).format('HH:mm');
    let eventEndMinutes: number = getMinFrom1200AM(endMomentTime);
    let eventStartMinutes: number = getMinFrom1200AM(startMomentTime);
    if (
      eventEndMinutes > startMinutes &&
      eventEndMinutes <= endMinutes &&
      eventStartMinutes < eventEndMinutes
    ) {
    } else if (eventEndMinutes === 0) {
      eventEndTime = moment(endDateTime).format('YYYY-MM-DD') + 'T00:00:00.000';
    } else {
      if (clear) {
        eventEndTime = null;
      } else {
        eventEndTime =
          moment(endDateTime).format('YYYY-MM-DD') +
          'T' +
          interval.endTime +
          ':00.000';
      }
    }
  } else {
    eventEndTime = null;
  }
  return eventEndTime;
}

export function getEndTime(
  clinic: ClinicSimpleQueryResult,
  endTime: any,
  startDateTime: any,
  duration: any
) {
  const newEndDateTime = getDurationEndTime(duration, startDateTime, endTime);
  return validateEndTimeRule(clinic, startDateTime, newEndDateTime);
}

export function setEventDuration(
  clinic: ClinicSimpleQueryResult,
  event: any,
  startDateTime: any,
  duration: any
) {
  let newEndDateTime = getDurationEndTime(
    duration,
    startDateTime,
    event.scheduleEvent.endTime
  );

  event.scheduleEvent.endTime = validateEndTimeRule(
    clinic,
    startDateTime,
    newEndDateTime
  );
  event.scheduleEvent.series.endTime = getDurationEndTime(
    duration,
    event.scheduleEvent.series.startTime,
    event.scheduleEvent.series.endDate
  ).slice(0, -4);
  return event;
}

export function validateStartTimeRule(
  clinic: ClinicSimpleQueryResult,
  startDateTime: any
) {
  const interval = getClinicStartEndTimeInterval(clinic);
  var eventStartTime = startDateTime;
  if (eventStartTime) {
    const startMinutes = getMinFrom1200AM(interval.startTime);
    const endMinutes = getMinFrom1200AM(interval.endTime);
    const startMoment: Moment = moment(eventStartTime);
    var eventMinutes = getMinFrom1200AM(startMoment.format('HH:mm'));
    var newEventMinutes = validateTimeInterval(
      eventMinutes,
      interval.regularMeetingTime
    );
    if (eventMinutes !== newEventMinutes) {
      eventMinutes = newEventMinutes;
      eventStartTime =
        moment(startDateTime).format('YYYY-MM-DD') +
        'T' +
        getTime(eventMinutes) +
        ':00.000';
    }
    if (eventMinutes >= startMinutes && eventMinutes < endMinutes) {
    } else {
      eventStartTime =
        moment(startDateTime).format('YYYY-MM-DD') +
        'T' +
        interval.startTime +
        ':00.000';
    }
  } else {
    eventStartTime =
      moment(new Date()).format('YYYY-MM-DD') +
      'T' +
      interval.startTime +
      ':00.000';
  }
  return eventStartTime;
}

export function getDateTimeSelection(clinic: any, event: any) {
  let startTimeOptions: any = [];
  let endTimeOptions: any = [];
  let eventStartTime = event.scheduleEvent.scheduleEventDateTime;
  if (clinic) {
    startTimeOptions = getStartTimeOpts(clinic);
    endTimeOptions = getEndTimeOpts(clinic, moment(eventStartTime));
  }

  let startDateTime = eventStartTime && moment(eventStartTime);
  let startTimeOpt = startDateTime && {
    value: startDateTime.format('HH:mm'),
    label: getStandartTimeFromMilitaryTime(startDateTime.format('HH:mm'))
  };
  let endDateTime = null;
  let endTimeOpt = null;
  if (event.scheduleEvent.endTime) {
    endDateTime = moment(event.scheduleEvent.endTime);
    var endTimeValue = endDateTime.format('HH:mm');
    var label = getStandartTimeFromMilitaryTime(endDateTime.format('HH:mm'));
    if (endTimeValue === '00:00') {
      endTimeValue = '24:00';
      label = '(Midnight)';
    }
    endTimeOpt = {
      value: endTimeValue,
      label: label
    };
  }
  return {
    startTimeOptions,
    endTimeOptions,
    startDateTime,
    startTimeOpt,
    endTimeOpt
  };
}

export function getProviderSelection(
  clinicProviders: any,
  selectedClinic: any,
  providerCodes: any
) {
  let filteredProviders = selectedClinic
    ? clinicProviders.filter(
        (cp: any) =>
          cp.clinicCode === selectedClinic.value && cp.showInAppointment
      )
    : clinicProviders;
  filteredProviders = _.uniqBy(filteredProviders, 'providerId');
  let providerOpts = convertProviders(filteredProviders);
  let selectedProviderNames: string = '';
  let selectedProviders = setSelectedProviders(providerCodes, providerOpts);
  selectedProviders?.forEach((s: any, index: number) => {
    selectedProviderNames =
      selectedProviderNames +
      s.label +
      (index !== providerCodes.length - 1 ? ', ' : '');
  });
  return {
    providerOpts,
    selectedProviders,
    selectedProviderNames
  };
}

export function setEventStartTime(
  payload: any,
  clinic: any,
  startTimeValue: any
) {
  let updatedPayload = { ...payload };
  let startDateTime = moment(
    updatedPayload.scheduleEvent.scheduleEventDateTime
  );
  let endDateTime = moment(updatedPayload.scheduleEvent.endTime);
  let newStartTime =
    startDateTime.format('YYYY-MM-DD') + 'T' + startTimeValue + ':00.000';
  updatedPayload.scheduleEvent.scheduleEventDateTime = validateStartTimeRule(
    clinic,
    newStartTime
  );

  let duration = calcDurationDateTime(startDateTime, endDateTime);
  updatedPayload = setEventDuration(
    clinic,
    updatedPayload,
    newStartTime,
    duration
  );

  let inSeries = updatedPayload.scheduleEvent.series?.typeFrequencyId > 0;
  if (!updatedPayload.scheduleEvent.series.startTime || !inSeries) {
    updatedPayload.scheduleEvent.series.startTime =
      updatedPayload.scheduleEvent.scheduleEventDateTime;
  }
  if (!updatedPayload.scheduleEvent.series.endTime || !inSeries) {
    updatedPayload.scheduleEvent.series.endTime =
      updatedPayload.scheduleEvent.endTime;
  }

  return updatedPayload;
}
