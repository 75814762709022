import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { MouseOverContainer, MouseOverContainerInner } from './styled';
import classNames from 'classnames';
import { IMouseOverRow } from '../types';
import { debouncer } from 'src/Framework/util/helps';
import Popover from '../../Popover';
import { Position } from './types';
import { v4 } from 'uuid';

interface IProps {
  mouseOverData: IMouseOverRow;
  index: number;
}

function isTouchDevice() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile;
  // return navigator.maxTouchPoints || 'ontouchstart' in document.documentElement;
}

const Component: FC<IProps> = ({ mouseOverData, index }: IProps) => {
  const [touchStarted, setTouchStarted] = useState(false);
  const hovered = useRef(false);
  const rowElementRef = useRef<HTMLDivElement | null>(null);
  const mouseHoverContainer = useRef(false);
  const debounceEnter = useRef(debouncer(250));
  const debounceLeave = useRef(debouncer(10));

  const debounceTouch = useRef(debouncer(500));

  const [openPopover, setOpenPopover] = useState(false);
  const [active, setActive] = useState(false);
  const [data, setData] = useState({
    offsetTop: 0,
    height: 0,
    width: 0,
    innerHeight: 0,
    position: Position.top,
    key: v4()
  });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);

  const onMouseLeaveChecker = useCallback(() => {
    debounceLeave.current(() => {
      if (!hovered.current && !mouseHoverContainer.current) {
        setActive(false);
      }
    });
  }, []);
  useEffect(() => {
    const rowElement = containerRef.current?.previousSibling as HTMLDivElement;
    if (rowElement && innerRef.current) {
      rowElementRef.current = rowElement;
      const tableElement = rowElement.offsetParent as HTMLDivElement;
      if (tableElement) {
        const calcData = () => {
          const innerHeight = innerRef.current?.clientHeight || 0;
          const offsetTop = rowElement.offsetTop;
          setData({
            key: v4(),
            offsetTop,
            height: rowElement.clientHeight,
            innerHeight,
            width: innerRef.current?.clientWidth || 0,
            position:
              offsetTop - innerHeight < tableElement.scrollTop
                ? Position.bottom
                : Position.top
          });
        };
        const activate = () => {
          setActive(true);
        };
        const calcAll = () => {
          if (containerRef.current) {
            containerRef.current.style.right = `${-tableElement.scrollLeft}px`;
          }
          calcData();
        };
        const mouseenter = (e: any) => {
          if (isTouchDevice()) return;
          hovered.current = true;
          calcAll();
          debounceEnter.current(() => {
            if (hovered.current) {
              activate();
            }
          });
        };
        const mouseleave = (e: any) => {
          if (isTouchDevice()) return;
          hovered.current = false;
          onMouseLeaveChecker();
        };
        let touchActive = false;
        const touchstart = () => {
          touchActive = true;
          calcAll();
          debounceTouch.current(() => {
            if (touchActive) {
              setTouchStarted(true);
              setOpenPopover(true);
            }
          });
        };
        const touchend = () => {
          touchActive = false;
        };
        rowElement.addEventListener('mouseenter', mouseenter);
        rowElement.addEventListener('mouseleave', mouseleave);
        rowElement.addEventListener('touchstart', touchstart);
        rowElement.addEventListener('touchend', touchend);
        rowElement.addEventListener('focusin', mouseenter);
        rowElement.addEventListener('focusout', mouseleave);
        calcData();
        return () => {
          rowElement.removeEventListener('mouseenter', mouseenter);
          rowElement.removeEventListener('mouseleave', mouseleave);
          rowElement.removeEventListener('touchstart', touchstart);
          rowElement.removeEventListener('touchend', touchend);
          rowElement.removeEventListener('focusin', mouseenter);
          rowElement.removeEventListener('focusout', mouseleave);
        };
      }
    }
    return () => {};
  }, []);
  const onMouseEnterContainer = useCallback(() => {
    mouseHoverContainer.current = true;
    if (rowElementRef.current) {
      rowElementRef.current.classList.add('hovered');
    }
  }, []);
  const onMouseLeaveContainer = useCallback(() => {
    mouseHoverContainer.current = false;
    onMouseLeaveChecker();
    if (rowElementRef.current) {
      rowElementRef.current.classList.remove('hovered');
    }
  }, []);

  const onOpenPopoverChange = useCallback((value: boolean) => {
    setOpenPopover(value);
    if (value === false) {
      setInterval(() => {
        setTouchStarted(false);
      }, 250);
    }
  }, []);
  return (
    // This popover needs for tablet devices
    <Popover
      id=""
      open={touchStarted && openPopover}
      onOpenChange={onOpenPopoverChange}
      globalOverlay={true}
      getPopupContainer={() => containerRef.current!}
      placement="left"
      content={mouseOverData.component({
        onOpenPopoverChange,
        active
      })}
    >
      <MouseOverContainer
        onMouseEnter={onMouseEnterContainer}
        onMouseLeave={onMouseLeaveContainer}
        onFocus={onMouseEnterContainer}
        onKeyDown={(e) => {
          if (
            !e.shiftKey &&
            e.code === 'Tab' &&
            (e.target as HTMLElement).id.includes('last-in-hover-popup')
          ) {
            onMouseLeaveContainer();
          }
        }}
        ref={containerRef}
        style={{
          top: touchStarted
            ? data.offsetTop
            : data.position === Position.bottom
            ? data.offsetTop + data.height - 1
            : data.offsetTop - data.innerHeight,
          maxWidth: active ? '1000px' : '0px'
        }}
      >
        <MouseOverContainerInner
          ref={innerRef}
          position={data.position}
          className={classNames({
            'mouseover-container': true,
            active: active
          })}
        >
          {mouseOverData.component({
            onOpenPopoverChange,
            active
          })}
        </MouseOverContainerInner>
      </MouseOverContainer>
    </Popover>
  );
};

export default React.memo(Component);
