import { IValidationResultTrace } from 'src/Activities/RuleEditor/Store/Models/RuleDefinition';
import 'moment-timezone';
import moment from 'moment';
import _ from 'lodash';

export const onDragCondition = (
  e: any,
  parentId: any,
  componentId: any,
  name: string,
  CompIndex: any
) => {
  e.stopPropagation();
  let compId = componentId ? componentId : null;
  let parId = parentId ? parentId : null;
  let tempName = name ? name : null;
  let index = CompIndex;
  e.dataTransfer.dropEffect = 'move';
  e.dataTransfer.setData(
    'text/plain',
    JSON.stringify({
      type: parentId ? 'component' : 'template',
      parentId: parId,
      componentId: compId,
      templateName: tempName,
      index: index
    })
  );
};

export const uppercaseFirstLatter = (string?: string): string => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
export const lowercaseFirstLatter = (string?: string): string => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toLowerCase() + string.slice(1).toLowerCase();
};

export const isContainerTraceComponent = (
  trace: IValidationResultTrace
): boolean => {
  return trace.children ? true : false;
};

export const roundOff = (rate: number) => {
  let complianceRate = Math.round(rate * 100) / 100;
  return complianceRate;
};

export const getPermissions = () => {
  let roleList: any = localStorage.getItem('roleList');
  let roleId = localStorage.getItem('roleId');
  let roles: any;
  let rolePermission: any = null;
  if (roleList) {
    roles = JSON.parse(roleList).roles;
    console.log(roles);
    let role = roles.find((role: any) => roleId == role.roleId);
    if (role) {
      rolePermission = role.Permissions;
      localStorage.setItem('roleName', role.name);
    }
  }
  return rolePermission;
};

export const distinct = (value: any) => {
  let distinctValues = new Set();
  distinctValues.add(value);
  return Array.from(distinctValues);
};

export const displayName = (subject: any, format: string) => {
  let updatedName = format.replace(
    'LastName',
    subject.lastName ? subject.lastName : ''
  );
  updatedName = updatedName.replace('FirstName', subject.firstName);
  updatedName = updatedName.replace(
    'SubjectId',
    subject.subjectId ? subject.subjectId : subject.id
  );
  return updatedName;
};

export const getDisplayConfig = () => {
  let displayConfig: any = localStorage.getItem('displayConfig');
  let config = JSON.parse(displayConfig);
  return config;
};

export const isValidCharacter = (str: any): boolean => {
  let regulaeExp = /^[a-zA-Z0-9\d\-_.,~!@$=;'\s]+$/g;
  if (str) {
    if (regulaeExp.test(str)) return true;
    else return false;
  }
  return true;
};

export const isValidNumber = (str: any): boolean => {
  let regulaeExp = /^[0-9]+$/g;
  if (str) {
    if (regulaeExp.test(str)) return true;
    else return false;
  }
  return true;
};

export const formattedName = (subject: any) => {
  let seperator = subject && subject.lastName ? ', ' : '';
  let updatedName = subject && subject.lastName + seperator + subject.firstName;
  return updatedName;
};
export const formattedPrefName = (subject: any) => {
  const firstPart = subject.nickName ? subject.nickName : subject.firstName;
  const prefName = firstPart + ' ' + subject.lastName;
  return prefName;
};
export const formattedNameFNLN = (subject: any) => {
  let seperator = subject && subject.lastName ? ' ' : ' ';
  let updatedName = subject && subject.firstName + seperator + subject.lastName;
  return updatedName;
};
export const formattedNameWithId = (subject: any) => {
  let seperator = subject && subject.lastName ? ', ' : '';
  let updatedName =
    subject &&
    subject.lastName +
    seperator +
    subject.firstName +
    ' ' +
    (subject.subjectId ? `(${subject.subjectId})` : '');
  return updatedName;
};

export const dec2bin = (dec: number) => {
  return (dec >>> 0).toString(2);
};

export const msToTime = (s: number) => {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;
  return hrs + ':' + mins;
};

export function isGoodDateMMDDYYY(dt: string) {
  var reGoodDate =
    /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
  return reGoodDate.test(dt);
}

export const durationAsString = (
  ms: number,
  maxPrecission = 3,
  withoutSecond = false
) => {
  const duration = moment.duration(ms);

  const items = [];
  items.push({ timeUnit: 'd', value: Math.floor(duration.asDays()) });
  items.push({ timeUnit: 'h', value: duration.hours() });
  items.push({ timeUnit: 'm', value: duration.minutes() });
  if (!withoutSecond) {
    items.push({ timeUnit: 's', value: duration.seconds() });
  }

  const formattedItems = items.reduce(
    (accumulator: any, { value, timeUnit }) => {
      if (
        accumulator.length >= maxPrecission ||
        (accumulator.length === 0 && value === 0)
      ) {
        return accumulator;
      }

      accumulator.push(`${value}${timeUnit}`);
      return accumulator;
    },
    []
  );

  return formattedItems.length !== 0 ? formattedItems.join(' ') : '-';
};

export const formattedDateTime = (dateTime: string | null | undefined) => {
  if (!dateTime) return '';
  return moment(dateTime).format('MM/DD/YYYY hh:mm A');
};

export const formattedShortDateTime = (dateTime: string) => {
  return moment(dateTime).format('MM/DD/YY hh:mm A');
};

export const formattedShortDate = (dateTime: string | null) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('MM/DD/YY');
};

export const formattedDateAndTimeToDateTime = (
  date: string | null | undefined,
  time: string | null | undefined
) => {
  if (!date || !time) return '';
  return `${moment(date).format('YYYY-MM-DD')}T${moment(time).format(
    'hh:mm:ss'
  )}`;
};

export const formattedDate = (dateTime: string | null | undefined) => {
  if (!dateTime) return '';
  return moment(dateTime).format('MM/DD/YYYY');
};
export const dateRangeFormat = 'MM-DD-YYYY';
export const formattedDateRange = (dateTime: string) => {
  //this will not work, it has to be like below
  return moment(dateTime).format(dateRangeFormat);
  // return moment(dateTime,dateRangeFormat).format(anotherDateRangeFormat);
};

export const formattedLTTime = (dateTime: string | null) => {
  return moment(dateTime).format('LT');
};

export const formattedTime = (dateTime: string | null | undefined) => {
  if (!dateTime) return '';
  return moment(dateTime).format('hh:mm A');
};
export const getMilitaryTimeFromStandart = (
  timeString: string | null | undefined
) => {
  if (!timeString) return '';
  return moment(timeString, 'HH:mm').format('hh:mm A');
};

export const sortByDate = <T>(data: T[], field: string): T[] => {
  return data.sort((a, b) => {
    // @ts-ignore
    return new Date(b[field]) - new Date(a[field]);
  });
};

export const getConvertedTimeForZone = (time: any) => {
  let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let convertedTimeObject;
  if (typeof time === 'string') {
    if (zone && time && time.includes('T') && !time.includes('Z')) {
      convertedTimeObject = new Date(time + 'Z');
    } else {
      convertedTimeObject = moment.tz(time, zone);
    }

    return convertedTimeObject ? convertedTimeObject : time;
  } else {
    return time;
  }
};

export const differObjectsDeep = <T>(newData: T, prevData: T): Partial<T> => {
  return Object.keys(newData).reduce((diff, key) => {
    if (_.isEqual(prevData[key], newData[key])) return diff;
    return {
      ...diff,
      [key]: newData[key]
    };
  }, {});
};

export const differObjects = <T>(newData: T, prevData: T): Partial<T> => {
  return Object.keys(newData).reduce((diff, key) => {
    if (prevData[key] === newData[key]) return diff;
    return {
      ...diff,
      [key]: newData[key]
    };
  }, {});
};

export const midString = (prev: string, next: string): string => {
  var p, n, pos, str;
  for (pos = 0; p == n; pos++) {
    // find leftmost non-matching character
    p = pos < prev.length ? prev.charCodeAt(pos) : 96;
    n = pos < next.length ? next.charCodeAt(pos) : 123;
  }
  str = prev.slice(0, pos - 1); // copy identical part of string
  if (p == 96) {
    // prev string equals beginning of next
    while (n == 97) {
      // next character is 'a'
      n = pos < next.length ? next.charCodeAt(pos++) : 123; // get char from next
      str += 'a'; // insert an 'a' to match the 'a'
    }
    if (n == 98) {
      // next character is 'b'
      str += 'a'; // insert an 'a' to match the 'b'
      n = 123; // set to end of alphabet
    }
  }
  // @ts-ignore
  else if (p + 1 == n) {
    // @ts-ignore           // found consecutive characters
    str += String.fromCharCode(p); // insert character from prev
    n = 123; // set to end of alphabet
    while ((p = pos < prev.length ? prev.charCodeAt(pos++) : 96) == 122) {
      // p='z'
      str += 'z'; // insert 'z' to match 'z'
    }
  }
  // @ts-ignore
  return str + String.fromCharCode(Math.ceil((p + n) / 2)); // append middle character
};

export const onChangeListOrder = (
  prev: number,
  next: number,
  fullList: Array<{ order: string }>,
  changeAction: Function
) => {
  const currentItem = { ...fullList[prev] };
  const b = fullList[next];
  if (next > prev) {
    // Down
    const c = fullList[next + 1];
    currentItem.order = midString(b.order, c ? c.order : '');
  } else {
    // Up
    const c = fullList[next - 1];
    if (!c) {
      // Move to first position
      const prevOrder = fullList[0].order;
      b.order = midString(prevOrder, fullList[1].order);
      currentItem.order = midString(prevOrder, b.order);
      changeAction(currentItem);
      changeAction(b);
      return;
    } else {
      currentItem.order = midString(c.order, b.order);
    }
  }
  changeAction(currentItem);
};
