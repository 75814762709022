import React, { FC, useRef, useState } from 'react';
import cn from 'classnames';
import { Input } from 'antd';
import { InputProps } from 'antd/es/input';
import IMask from 'imask';
import { v4 } from 'uuid';

import { ICommon, IInputEditConfirm } from '../index';

import EditApprove, { IModalHandles } from '../EditApprove';
import { SearchIconSvg } from 'src/Framework/Common/Svg/SearchIconSvg';

export const MASKS = {
  zip: '00000-0000',
  phone: '(000) 000-0000 Ext-[00000]',
  phoneHome: '(000) 000-0000',
  socialSecurity: '000-00-0000'
} as const;

type TypeMask = keyof typeof MASKS;

export const getMaskOptions = (mask: TypeMask, value: string) => {
  const masked = IMask.createMask({
    mask: MASKS[mask]
  });
  masked.resolve(value);
  return {
    value: masked.value,
    unmaskedValue: masked.unmaskedValue,
    isComplete: masked.isComplete
  };
};

export interface IInput extends ICommon {
  type: 'input';
  value?: string | null | undefined;
  onChange?: (value: string) => any;
  className?: string;
  disabled?: boolean;
  inputProps: Partial<InputProps>;
  inputEditConfirm?: IInputEditConfirm;
  /**
   * @param inputMask mask field you can find all masks here -> {@link MASKS}
   * if you need get unmask value or use mask out of input you can use this function {@link getMaskOptions}
   */
  inputMask?: TypeMask;
  searchIcon?: boolean;
}

export const searchSvgIcon = <SearchIconSvg />;

const Component: FC<IInput> = (props: IInput) => {
  const EditApproveRef = useRef<IModalHandles>(null);
  const InputRef = useRef<any>(null);
  const labelIdRef = useRef(v4());
  const {
    star,
    label,
    bottomMargin,
    value,
    onChange,
    className,
    disabled,
    inputProps,
    inputEditConfirm,
    inputMask,
    hideLabel,
    searchIcon,
    id
  } = props;
  const [editRejected, setEditRejected] = useState<boolean>(true);
  const areaLabel = typeof label === 'string' ? label : '';
  return (
    <>
      {inputEditConfirm && (
        <EditApprove
          ref={EditApproveRef}
          data={inputEditConfirm}
          onApprove={() => {
            setEditRejected(false);
          }}
          onReject={() => {
            setEditRejected(true);
          }}
        />
      )}
      <label
        id={labelIdRef.current}
        htmlFor={inputProps.id ? inputProps.id : id}
        className="visually-hidden"
      >
        {label}
      </label>
      <Input
        aria-labelledby={labelIdRef.current}
        aria-required={props.star}
        id={id}
        prefix={searchIcon ? searchSvgIcon : undefined}
        {...inputProps}
        value={
          inputMask
            ? getMaskOptions(inputMask, value || '')?.value
            : value
            ? value
            : ''
        }
        className={cn({
          select: true,
          margin: bottomMargin,
          [`${className}`]: className
        })}
        placeholder={
          inputProps?.hasOwnProperty('placeholder')
            ? inputProps?.placeholder
            : hideLabel
            ? areaLabel
            : ''
        }
        onClick={() => {
          if (inputEditConfirm && editRejected) {
            EditApproveRef.current?.show({});
          }
        }}
        onChange={(e) => {
          if (onChange) {
            if (inputEditConfirm && editRejected) return;
            let text = e.target.value;
            if (inputProps.maxLength) {
              text = text.slice(0, inputProps.maxLength);
            }
            if (inputMask) {
              const unmask = getMaskOptions(inputMask, text)?.unmaskedValue;
              onChange(unmask);
            } else {
              onChange(text);
            }
          }
        }}
        onFocus={(e) => {
          if (inputProps.onFocus) {
            inputProps.onFocus(e);
          }
        }}
        disabled={disabled || inputProps?.disabled}
        required={star}
        ref={InputRef}
      />
    </>
  );
};

export default Component;
